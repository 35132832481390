import {createAction, createReducer} from "@reduxjs/toolkit";
import {Lists} from "../../assets/mockData/mockData";

const GET_LIST_IDEAS = createAction('get mock list ideas');
const ADD_IDEA = createAction('add mockidea');
const GET_LIST_IDEAS_FAILED = createAction('get mock list ideas failed');
const UPDATE_IDEA = createAction('update mock list idea document');
const REMOVE_IDEA = createAction('remove mock list idea document');

const mockActionsFirestore = {
  GET_LIST_IDEAS,
  GET_LIST_IDEAS_FAILED,
  UPDATE_IDEA,
  ADD_IDEA,
  REMOVE_IDEA
};

const initialState = {
  MOCK_LIST_IDEAS: [],
}

function getDocuments() {

  try {
    return {
      LIST_IDEAS: Lists.LIST_CARDS
    }

  } catch (e) {
    return {
      LIST_IDEAS: null
    }
  }

}

const mockFirestoreReducer = createReducer(initialState, {

  [GET_LIST_IDEAS]: (state, action) => {

    const docs = getDocuments()
    // console.log('docs', state.MOCK_LIST_IDEAS)

    return {
      ...state,
      MOCK_LIST_IDEAS: docs.LIST_IDEAS
    }

  },
  [ADD_IDEA]: (state, action) => {

    // console.log('Payload: ', action.payload)
    const listCopy = state.MOCK_LIST_IDEAS.slice()
    listCopy.splice(0, 0, action.payload)

    return {
      ...state,
      MOCK_LIST_IDEAS: listCopy,
    }

  },
  [UPDATE_IDEA]: (state, action) => {

    // console.log('payload', action.payload.id)
    const indexOfDoc = state.MOCK_LIST_IDEAS?.findIndex((doc) => (doc).id === action.payload.id)

    const listCopy = state.MOCK_LIST_IDEAS.slice()
    listCopy.splice(indexOfDoc, 1, action.payload)

    console.log('UPDATING IDEA')

    return {
      ...state,
      MOCK_LIST_IDEAS: listCopy,
    }


  },
  [REMOVE_IDEA]: (state, action) => {

    // console.log('Payload: ', action.payload)
    const indexOfDoc = state.MOCK_LIST_IDEAS?.findIndex((doc) => (doc).id === action.payload.id)
    const listCopy = state.MOCK_LIST_IDEAS.slice()
    listCopy.splice(indexOfDoc, 1)

    console.log('REMOVING IDEA')

    return {
      ...state,
      MOCK_LIST_IDEAS: listCopy,
    }

  },
  [GET_LIST_IDEAS_FAILED]: (state, action) => ({
    ...state,
    MOCK_LIST_IDEAS: null,
  }),
})

export {mockActionsFirestore, mockFirestoreReducer};
export const mockListIdea = (state) => state.mockFirestore.MOCK_LIST_IDEAS;
