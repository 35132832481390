import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page22 = () => {

  return (
    <MethodLayout
      title={"2.2 Future pressrelease"}
      prevNum={2.1}
      nextNum={2.3}
    >

      <b className='methods-layout-content-description'>Följande metod används av Google, Amazon och Microsoft för att koka ner och formulera ett problemområde. Ett framtida pressmeddelande kan hjälpa till att fokusera på det önskade resultatet och få alla i gruppen på samma spår.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <p>För att börja skriva pressmeddelandet, svara på dessa frågor:</p>

      <div className='background-white-with-opacity' style={{ display: 'flex', maxWidth: '600px', marginTop: '1rem', marginBottom: '1rem', columnGap: '2rem' }}>
        <ul className='text-decoration-none'>
          <li>Vad heter din produkt/tjänst?</li>
          <li>Hur ser den ut?</li>
          <li>Vilket problem löser den här produkten?</li>
          <li>Vilken målgrupp riktar sig produkten mot?</li>
          <li>Vad gör denna produkt extraordinär?</li>
          <li>Hur skulle din favoritkund beskriva upplevelsen av produkten?</li>
        </ul>
      </div>

      <ol>
        <li>Skriv en problemformulering som produkten/tjänsten ska lösa stort på whiteboarden / projektorn, ex. hur får vi människor att göra mer klimatsmarta val i sin vardag?
        </li>
        <li>Använd toolie.se/groupie för att dela in klassen i grupper om 3 - 4 pers med en framslumpad gruppledare.</li>
        <li>Ge de grupperna 15 minuter på sig att skriva ett så bra pressmeddelande som möjligt.</li>
        <li>Avsluta med att samtliga grupper läser upp sitt pressmeddelande.</li>
      </ol>

    </MethodLayout>
  )
}
