import React, { useState } from "react";
import { Form, Input } from "antd";
import { FORM_STATIC_TEXT } from "../../Forms/formConstants";
import { INPUT_IDEA_TITLE_CHAR_COUNT_MAX } from "../../../util/integers";
import { TextFieldCharCounter } from "./TextFieldCharCounter";
import { BASE_PROJECT_COLORS as COLORS } from "../../../util/colorConstants";
import { HighlightTwoTone } from "@ant-design/icons";

const MISSING_TITLE = "Titel saknas!"

export const InputTitleIdea = ({ useClass, initialValue, disabled }) => {

  const [charCount, setCharCount] = useState(0)

  const updateCharCount = (e) => {
    // console.log('Count Text :', form.getFieldsValue().text)
    setCharCount(e.target.value.length)
  }

  return (
    <React.Fragment>
      <Form.Item
        name="title"
        type={'text'}
        initialValue={initialValue}
        style={{ color: COLORS.DARK }}
        rules={[
          {
            required: true,
            message: MISSING_TITLE,
          },
        ]}
      >

        <Input
          className={useClass}
          allowClear
          disabled={disabled}
          bordered={false}
          maxLength={INPUT_IDEA_TITLE_CHAR_COUNT_MAX}
          prefix={<HighlightTwoTone twoToneColor={COLORS.LIGHTER}
            style={{ fontSize: 18, marginRight: 3 }} />}
          placeholder={FORM_STATIC_TEXT.PLACEHOLDER_TITLE}
          onChange={e => updateCharCount(e)}
        />


      </Form.Item>
      <TextFieldCharCounter charCount={charCount} max={INPUT_IDEA_TITLE_CHAR_COUNT_MAX} />
    </React.Fragment>
  )

}
