import { React } from 'react'
import { BaseCard } from '../base'
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const MethodCard = ({ num, title, content, footer }) => {

  const navigate = useNavigate();

  const onCardClick = useCallback((n) => {
    navigate(`/methods/${n}`)
  }, [navigate])

  return (
    <BaseCard
      className={'Card'}
      header={<MethodCardHeader num={num} title={title} />}
      content={<MethodCardContent description={content} />}
      footer={footer}
      onClick={() => onCardClick(num)}
    />
  )
}


export const MethodCardContent = ({ description }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <p style={{ fontSize: 14, fontWeight: 400 }}>{description}</p>
    </div>
  )
}


export const MethodCardHeader = ({ num, title }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: 50 }}>
      <h2 style={{ fontSize: 22, fontWeight: 400 }}>{num}</h2>
      <h3 style={{ fontSize: 22, fontWeight: 600 }}>{title}</h3>
    </div>
  )
}