import React, {useState} from "react";
import {Form, Input} from "antd";
import {TEXT_AREA_IDEA_CHAR_COUNT_MAX} from "../../../util/integers";
import {TextFieldCharCounter} from "./TextFieldCharCounter";
import {STATIC_TEXT} from "../../../util/staticTextConstants";


export const TextAreaIdea = ({useClass, initialValue}) => {

  const [charCount, setCharCount] = useState(0)

  const updateCharCount = (e) => {
    // console.log('Count Text :', form.getFieldsValue().text)
    setCharCount(e.target.value.length)
  }

  return(
     <React.Fragment>
       <Form.Item
          name="text"
          initialValue={initialValue}
          rules={[
            {
              required: true,
              message: 'Ange en idé!',
            },
          ]}

       >

         <Input.TextArea
            className={useClass}
            bordered={false}
            placeholder={STATIC_TEXT.SUPPLY_AN_IDEA}
            maxLength={TEXT_AREA_IDEA_CHAR_COUNT_MAX}
            style={{minHeight: '200px'}}
            onChange={e => updateCharCount(e)}
         />

       </Form.Item>

       <TextFieldCharCounter charCount={charCount} max={TEXT_AREA_IDEA_CHAR_COUNT_MAX}/>

     </React.Fragment>
  )

}
