import React from 'react';
import {IdeaList} from "../components/Ideas/IdeaList";

const PageIdea = () => {

  return (
     <React.Fragment>
       <IdeaList/>
     </React.Fragment>
  )
}
export default PageIdea;
