import { ArrowLeftOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import './../../styles/methods.css'

export const MethodLayout = (props) => {

  const navigate = useNavigate();

  const onNavigateBack = useCallback(() => {
    navigate('/methods')
  }, [navigate])


  const onNavigatePrev = useCallback((prevNum) => {
    navigate(`/methods/${prevNum}`)
  }, [navigate])

  const onNavigateNext = useCallback((nextNum) => {
    navigate(`/methods/${nextNum}`)
  }, [navigate])

  return (
    <div className='method-layout'>
      <div
        onClick={() => onNavigateBack()}
      >
        <ArrowLeftOutlined />
        <h1 className='methods-layout-title'>{props.title}</h1>
      </div>

      <div className='methods-layout-content'>
        {props.children}

        <nav style={{ display: 'flex', bottom: 0, justifyContent: 'center', columnGap: '2rem', marginTop: '2rem' }}>
          {props.prevNum && (
            <LeftCircleFilled className='hover-able' onClick={() => onNavigatePrev(props.prevNum)} style={{ fontSize: 32 }} />
          )}
          {props.nextNum && (
            <RightCircleFilled className='hover-able' onClick={() => onNavigateNext(props.nextNum)} style={{ fontSize: 32 }} />
          )}

        </nav>

      </div>
    </div>
  )
}
