export const MethodInformation = ({ minutesFrom, minutesTo, individual, groupSizeMin, groupSizeMax, materials }) => {

  return (
    <div className='method-information-container background-white-with-opacity query-flex-row'>

      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <b>Tidsåtgång</b>
        <p>{`${minutesFrom}-${minutesTo} min`}</p>
      </section>

      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <b>Gruppstorlek</b>

        {individual && (
          <p>Individuellt</p>
        )}

        {groupSizeMin && groupSizeMax && (
          <p>{`${groupSizeMin}-${groupSizeMax} pers`}</p>
        )}


      </section>

      <section style={{ display: 'inline-flex', flexDirection: 'column' }}>
        <b>Material</b>
        <p>{[...materials].join(", ")}</p>
      </section>
    </div>
  )
}