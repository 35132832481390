import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";
import {FIRESTORE_API_RESPONSES} from "./firestoreResponsesAPI";


const initialState = {
  LIST: [],
  status: FIRESTORE_API_RESPONSES.DEFAULT_STATE,
  UPLOAD_STATUS: FIRESTORE_API_RESPONSES.DEFAULT_STATE,
  DOCUMENT_CREATE_STATUS: FIRESTORE_API_RESPONSES.DEFAULT_STATE,
  DOCUMENT_UPDATE_STATUS: FIRESTORE_API_RESPONSES.DEFAULT_STATE,
};

export const UPLOAD_STATUSES = {
  IDLE: 'idle',
  UPLOADING: 'uploading',
  UPLOADING_SUCCESS: 'upload success',
  UPLOADING_FAILED: 'upload failed',
}

export const createIdea = createAsyncThunk(
   'firestoreSlice/setIdea',
   async (data) => {

     const cardNumber = await firestoreAPI.getLatestCardNumber()
     Object.assign(data, {card_number: cardNumber})
     await firestoreAPI.incrementLatestCardNumber()
     return await firestoreAPI.setIdea(data)

   }
);

export const updateIdea = createAsyncThunk(
   'firestoreSlice/updateIdea',
   async (data) => {

     return await firestoreAPI.updateIdea(data)

   }
);

export const firestoreIdeaSlice = createSlice({
  name: 'firestoreSlice',
  initialState,

  reducers: {
    clearDocumentCreateStatus: (state) => {
      state.DOCUMENT_CREATE_STATUS = FIRESTORE_API_RESPONSES.DEFAULT_STATE
    },
    clearDocumentUpdateStatus: (state) => {
      state.DOCUMENT_UPDATE_STATUS = FIRESTORE_API_RESPONSES.DEFAULT_STATE
    },
    documentAdded: (state, action) => {

      try {
        const arrCopy = state.LIST.slice()
        arrCopy.push(action.payload)
        state.LIST = arrCopy
      } catch (e) {
        console.log('firestoreSlice:::documentAdded::Error trying to add document to LIST', e)
      }

    },
    documentModified: (state, action) => {

      try {
        const indexOfDoc = state.LIST.findIndex((doc) => (doc).documentId === action.payload.documentId)
        const arrCopy = state.LIST.slice()
        arrCopy.splice(indexOfDoc, 1, action.payload)
        state.LIST = arrCopy
        // state.DOCUMENT_UPDATE_STATUS = FIRESTORE_API_RESPONSES.SUCCESS_UPDATING_DOC;
      } catch (e) {
        // state.DOCUMENT_UPDATE_STATUS = FIRESTORE_API_RESPONSES.ERROR_UPDATING_DOC;
        console.log('firestoreSlice:::documentModified::: Error trying to modify document in LIST', e)
      }

    },
    documentRemoved: (state, action) => {
      try {
        const indexOfDoc = state.LIST.findIndex((doc) => (doc).id === action.payload.documentId)
        const arrCopy = state.LIST.slice()
        arrCopy.splice(indexOfDoc, 1)
        state.LIST = arrCopy
      } catch (e) {
        console.log('firestoreSlice:::documentRemoved::: Error trying to remove document in LIST', e)
      }
    },
  },

  extraReducers: (builder) => {
    builder
       // .addCase(getIdeas.pending, (state) => {
       //   state.status = 'loading';
       // })
       // .addCase(getIdeas.fulfilled, (state, action) => {
       //   state.status = 'IDLE';
       //   state.LIST = action.payload;
       // })
       // .addCase(getIdeas.rejected, (state, action) => {
       //   state.status = 'IDLE';
       // })
       .addCase(createIdea.pending, (state) => {
         state.DOCUMENT_CREATE_STATUS = FIRESTORE_API_RESPONSES.DEFAULT_STATE;
       })
       .addCase(createIdea.fulfilled, (state, action) => {
         console.log('SUCCESS:::', action.payload)
         state.DOCUMENT_CREATE_STATUS = action.payload;
       })
       .addCase(createIdea.rejected, (state, action) => {
         state.DOCUMENT_CREATE_STATUS = action.payload;
       })
  },

});

export const {
  clearDocumentCreateStatus,
  clearDocumentUpdateStatus,
  documentAdded,
  documentModified,
  documentRemoved,
} = firestoreIdeaSlice.actions;
export const listIdeas = (state) => state.firestoreIdea.LIST;
export const createIdeaUploadStatus = (state) => state.firestoreIdea.DOCUMENT_CREATE_STATUS;
export const updateIdeaUploadStatus = (state) => state.firestoreIdea.DOCUMENT_UPDATE_STATUS;

export default firestoreIdeaSlice.reducer;
