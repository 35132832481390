import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {FirebaseAuthProvider} from "@react-firebase/auth";
import {firebaseConfig} from "./firebase/firebaseConfig";
import firebase from "firebase/app";

ReactDOM.render(
   <React.StrictMode>
     <Provider store={store}>
       <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
         <App/>
       </FirebaseAuthProvider>
     </Provider>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
