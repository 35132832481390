// OLD: PURPLE/PINK THEMING
// export const BASE_PROJECT_COLORS = {
//   PURPLE_LIGHT: 'rgb(197, 6, 141)',
//   PURPLE_BADGER: 'rgb(209, 7, 143)',
//   PURPLE_LIGHTER: 'rgba(197, 6, 141, 0.7)',
//   PURPLE_DARK: 'rgb(75, 0, 130)',
//   DARK_GRAY: 'rgb(45, 45, 45)',
// }

export const BASE_PROJECT_COLORS = {
  LIGHT: 'rgb(8, 221, 157)',
  BADGER: 'rgb(0, 133, 78)',
  LIGHTER: 'rgba(8, 221, 157, 0.7)',
  DARK: 'rgb(0, 92, 42)',
  DARK_GRAY: 'rgb(45, 45, 45)',
}
