import { Form, Input } from "antd";
import { MailTwoTone } from "@ant-design/icons";
import { BASE_PROJECT_COLORS as COLORS } from "../../../util/colorConstants";
import { FORM_STATIC_TEXT } from "../../Forms/formConstants";
import React from "react";

export const InputEmail = ({ email }) => {

   return (
      <Form.Item
         type={'email'}
      >

         <Input
            className={'Input-email'}
            bordered={false}
            value={email}
            prefix={<MailTwoTone twoToneColor={COLORS.LIGHTER}
               style={{ fontSize: 18, marginRight: 3 }} />}
            placeholder={FORM_STATIC_TEXT.PLACEHOLDER_EMAIL_ADDRESS}

         />

      </Form.Item>
   )
}
