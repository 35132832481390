export const IdeaNew = (title, text, email, userId) => {
  return {
    title: title,
    card_number: 0,
    text: text,
    email: email,
    hearted_by: [], //ARRAY WITH USER_IDS -> COUNT.LENGTH
    comments: [],
    creation_date: `${new Date().getTime()}`,
    created_by: userId,
  }
}
