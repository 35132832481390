import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { STATIC_TEXT } from "../../util/staticTextConstants";

export const MoveToAuthSignInButton = () => {

  const navigate = useNavigate()

  const handleMoveToSignIn = () => {
    navigate('/not-signed-in')
  }

  return (
    <React.Fragment>
      <div className={'Sign-in-container'}>
        <img className={'Sign-in-logo'} src={'/LogoEx.svg'} alt={'logo'} />
        <b className={'Sign-in-title'}>{STATIC_TEXT.SIGN_IN_REQUIRED}</b>
        <p className={'Sign-in-text'}> {STATIC_TEXT.SIGN_IN_REQUIRED_DESCRIPTION}</p>

        <Button
          type={'default'}
          onClick={
            () => handleMoveToSignIn()
          }>{STATIC_TEXT.CONTINUE}</Button>
      </div>
    </React.Fragment>
  )
}
