import React from 'react'
import './TimeLine.css'

const title = <h1>NTI Inkubator</h1>
const ingress = <header>Framtidens innovation börjar här!!</header>
const body = <span>Framtidens innovation börjar här!



  Vi vill att NTI Gymnasiet ska verka som en inbjudande mötesplats med unika samarbeten där vi gemensamt intar en ledande roll för att möta elevers behov med nya lösningar.

  Vi vill innovera och skapa förutsättningar för att möta ett verkligt behov eller problem och stärka vår förståelse kring hur de innovationer som utvecklas skapar värde för våra elever, våra medarbetare och hela vår verksamhet.

  Vår ambition är att rikta engagemang, bygga kompetens och innovationskapacitet för att underlätta steget från att ha en idé till att kunna omsätta den skarpt, till att skala upp den för att skapa värde för så många som möjligt inom våra skolor - och övriga skolsverige.

  Vi vill möjliggöra att NTI Gymnasiet blir innovationsstarkt, som är uthålligt - över tid. </span>
const extras = "Vilken app skulle åtgärda “skav” du har i ditt klassrum?"

export const AboutProject = () => {

  return (
    <div className={'About-project-container'}>
      {title}
      {ingress}
      {/* {body} */}
      <ul>
        <li>Vi vill att NTI Gymnasiet ska vara en gymnasieskola i framkant, en katalysator för nya idéer och smartare arbetssätt.</li>
        <li>Vi vill att NTI Gymnasiet ska verka som en inbjudande mötesplats med unika samarbeten där vi gemensamt intar en ledande roll för att möta elevers behov med nya lösningar. </li>
        <li>Vi vill innovera och skapa förutsättningar för att möta ett verkligt behov eller problem och stärka vår förståelse kring hur de innovationer som utvecklas skapar värde för våra elever, våra medarbetare och hela vår verksamhet.
        </li>
        <li>Vår ambition är att rikta engagemang, bygga kompetens och innovationskapacitet för att underlätta steget från att ha en idé till att kunna omsätta den skarpt, till att skala upp den för att skapa värde för så många som möjligt inom våra skolor - och övriga skolsverige. </li>
        <li>Vi vill möjliggöra att NTI Gymnasiet blir innovationsstarkt, som är uthålligt - över tid.</li>
      </ul>
      {/* <i>{extras}</i> */}

    </div>
  )

}
