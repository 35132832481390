import React, { useEffect, useState } from "react";
import { Badge, Divider, message, Row, Tooltip } from "antd";
import { BASE_PROJECT_COLORS, BASE_PROJECT_COLORS as COLORS } from "../../util/colorConstants";
import { EditOutlined, HeartFilled, HeartOutlined, MessageOutlined } from "@ant-design/icons";
import { BASE_ICON_COLORS } from "../Forms/formConstants";
import { ModalComments } from "./ModalComments";
import { useDispatch, useSelector } from "react-redux";
import './Ideas.css'
import { STATIC_TEXT } from "../../util/staticTextConstants";
import { updateIdea } from "../../features/firebase/firestoreIdeaSlice";
import firebase from "firebase";
import { getHeartedCount, userHasHearted } from "../../util/util";
import { BUTTON_TIMEOUT_MILLIS, DURATION_SECONDS_TWO } from "../../util/integers";
import ModalFormEditIdea from "./ModalFormEditIdea";
import { authedUser } from "../../features/firebase/firebaseAuthReducer";
import { DisplayNotification } from "../../firebase/notifications";

export const IdeaCard = ({ item }) => {

  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalEditVisible, setModalEditVisible] = useState(false)
  const user = useSelector(authedUser)
  const [isHearted, setIsHearted] = useState(userHasHearted(item.hearted_by, user ? user.uid : false))
  // const uploadState = useSelector(updateIdeaUploadStatus);

  const handleHeartedClick = (item) => {
    setIsHearted(!isHearted)
    // let newItem = {...item}
    // newItem.hearted = !isHearted
    // dispatch(mockActionsFirestore.UPDATE_IDEA(newItem))

    const newDocData =
      !isHearted ?
        { hearted_by: firebase.firestore.FieldValue.arrayUnion(user.uid) }
        :
        { hearted_by: firebase.firestore.FieldValue.arrayRemove(user.uid) }

    dispatch(
      updateIdea(
        {
          docId: item.documentId,
          updateData: newDocData
        }
      )
    )
  }

  const displayModalComments = () => {
    setModalVisible(!modalVisible)
    //TODO: SET STUFF
  }

  const displayModalIdeaEdit = () => {
    setModalEditVisible(!modalEditVisible)
  }

  useEffect(() => {
    if (!modalVisible) return
    message.info(`Display comments for ${item.id}`)
  }, [modalVisible])

  return (
    <div className={"Cards"}>
      <Row className={'Card-top-row'} justify={'end'} align={'top'}>
        {/*<IconButtonComments item={item} displayModalComments={() => displayModalComments()}/>*/}
        <IconEdit user={user} item={item} displayModalIdeaEdit={displayModalIdeaEdit} />
        <IconButtonHeart user={user} isHearted={isHearted} item={item}
          handleHeartedClick={() => handleHeartedClick(item)} />
      </Row>

      <Row align={'bottom'}>
        <b className={'Card-title'}>{item.title}</b>
      </Row>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <Row>
        <div className={'Card-text'}>{item.text}</div>
      </Row>

      <Row>
        <div className={'Card-number'}>{`#${item.card_number}`}</div>
      </Row>

      {/*<Row style={{marginTop: 10}}>*/}
      {/*  <div style={{fontSize: 10}}>{item.email}</div>*/}
      {/*</Row>*/}

      <ModalComments modalVisible={modalVisible} displayModalComments={displayModalComments} item={item} />
      <ModalFormEditIdea modalEditVisible={modalEditVisible} displayModalIdeaEdit={displayModalIdeaEdit} item={item} />

    </div>
  )
}

const IconButtonHeart = ({ user, isHearted, item, handleHeartedClick }) => {

  const [isClickable, setIsClickable] = useState(true)
  const heartOnClick = user && isClickable
    ? () => {
      handleHeartedClick(item);
      onClickTimeout()
    }
    : user && !isClickable
      ? null
      : () => {
        DisplayNotification(STATIC_TEXT.NOTIFICATION_NOT_SIGNED_IN_TITLE, STATIC_TEXT.NOTIFICATION_NOT_SIGNED_IN_TEXT, DURATION_SECONDS_TWO)
      }

  const onClickTimeout = () => {

    setIsClickable(false)

    setTimeout(() => {
      setIsClickable(true)
    }, BUTTON_TIMEOUT_MILLIS)
  }

  return (
    <Badge color={COLORS.DARK_GRAY} count={getHeartedCount(item.hearted_by)} size={'small'} showZero>
      {
        isHearted ?
          <HeartFilled
            className={'Icon-heart'}
            style={{ color: BASE_ICON_COLORS.heart }}
            onClick={heartOnClick}
          />
          :
          <HeartOutlined
            className={'Icon-heart'}
            onClick={heartOnClick}
          />
      }
    </Badge>
  )
}

// const IconEdit = ({user, item, displayModalIdeaEdit}) => {
//
//   return (
//
//         <EditOutlined
//            className={'Icon-edit'}
//            size={'medium'}
//            onClick={displayModalIdeaEdit}
//         />
//   )
// }
const IconEdit = ({ user, item, displayModalIdeaEdit }) => {

  return (
    user?.uid === item.created_by ?
      <EditOutlined
        className={'Icon-edit'}
        size={'medium'}
        onClick={displayModalIdeaEdit}
      />
      : null
  )
}


const IconButtonComments = ({ item, displayModalComments }) => {

  return (
    <Tooltip
      title={STATIC_TEXT.COMMENT}
      color={COLORS.DARK_GRAY}
    >
      <Badge count={item.comments ? item.comments.length : 0} size={'small'}
        color={BASE_PROJECT_COLORS.BADGER}>

        <MessageOutlined
          className={'Icon-comment'}
          onClick={displayModalComments}
          size={'medium'}
          twoToneColor={BASE_ICON_COLORS.chat_blue}
        />

      </Badge>

    </Tooltip>
  )
}
