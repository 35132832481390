import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";

export const RouterContent = () => {

  return (
    <div className={"Content"}>
      <Routes>

        {/* App */}
        <Route path={routes[0].path} element={routes[0].Component} />
        {/* Methods */}
        <Route path={routes[1].path}>
          <Route index={true} element={routes[1].Component} />
          {routes[1].childRoutes.map((subRoute) => (
            <Route index={false} path={`/methods/${subRoute.path}`} element={subRoute.Component} />
          ))}
        </Route>

        <Route path={routes[2].path} element={routes[2].Component} />
        <Route path={routes[3].path} element={routes[3].Component} />
        <Route path={routes[4].path} element={routes[4].Component} />
        <Route path={routes[5].path} element={routes[5].Component} />
        <Route path={routes[6].path} element={routes[6].Component} />
        <Route path={routes[7].path} element={routes[7].Component} />

      </Routes>
    </div>
  )

}


//     {routes.map(({ path, Component, exact }) => (
//       <Route key={path} exact={exact} path={path}>
//         {({ match }) => (
//           <CSSTransition
//             in={match != null}
//             timeout={300}
//             classNames="content"
//             unmountOnExit
//           >
//             <div className={"Content"}>
//               {Component}
//             </div>
//           </CSSTransition>
//         )}
//       </Route>
//     ))}
