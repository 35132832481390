import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page23 = () => {

  return (
    <MethodLayout
      title={"2.3 GIF Story time"}
      prevNum={2.2}
      nextNum={3.1}
    >
      <b className='methods-layout-content-description'>
        Människan älskar historier. De får oss att förstå problem och människor på ett sätt som. Det nyttjar vi i denna metod där du med stöd av GIF:ar ska summera och förklara problemet. <br />
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <lh>Som hjälp att skriva en kort story som förklarar din problemformulering på ett bra och tydligt sätt är de 3 P:na:</lh>
      <div className='background-white-with-opacity' style={{ display: 'flex', maxWidth: '800px', marginTop: '1rem', marginBottom: '1rem', columnGap: '2rem' }}>
        <ul className='text-decoration-none'>
          <li className='li-row'>
            <b>Personer</b>
            <p>Vilka har problemet?</p>
          </li>
          <li className='li-row'>
            <b>Platser</b>
            <p>Var har man problemet?</p>
          </li>
          <li className='li-row'>
            <b>Problem</b>
            <p>Vad är problemet egentligen och hur drabbar det personen?</p>
          </li>
        </ul>
      </div>

      <ol>
        <li>Skriv en problemformulering stort på whiteboarden / projektorn, ex. en svensk slänger i genomsnitt 449 kg per år, det är ett problem.</li>
        <li>Använd toolie.se/groupie för att dela in klassen i grupper om 3 - 4 pers med en framslumpad gruppledare.</li>
        <li>I grupp ska eleverna på 15 minuter författa en kort historia (med stöd av de 3 p:na ) som förklarar det givna problemet. Denna historia ska också illustreras med hjälp av GIF:ar, från ex. Giphy.com eller annan GIF-tjänst. De kan sätta samman GIF:arna i ett presentationsvertyg, ex. Google slides eller Powerpoint.</li>
        <li>Avsluta med att samtliga grupper läser upp sitt pressmeddelande.</li>
      </ol>

    </MethodLayout>
  )
}
