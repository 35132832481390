import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page41 = () => {

  return (
    <MethodLayout
      title={"4.1 Paper prototyping"}
      prevNum={3.3}
      nextNum={4.2}
    >
      <b className='methods-layout-content-description'>
        Pappersprototyper är ett utmärkt verktyg för att utveckla idéer och designa användarflöden med hjälp av pappersskisser. Metoden lämpar sig extra bra för digitala produkter, men även design av fysiska produkter går. <br />
        Pappersprototypers syfte är att fånga en känsla för någonting och att enkelt kunna få feedback gällande användarupplevelsen.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Skriv en lösningsidé stort på whiteboarden / projektorn. Ex. <i>en app som spelifierar matsvinn. Minst matsvinn vinner.</i></li>
        <li>Dela ut tomma A4-papper och pennor till de studerande. Vik pappret en gång på längden och en på bredden. Nu har du fyra rutor att fylla med skisser. Påminn de studerande att det inte behöver vara snyggt utan den handlar om “de breda penseldragen”.</li>
        <li>Uppmuntra dem att under 20 minuter visuellt skissa ner produktens grundfunktionalitet i de olika rutorna. Behövs fler papper kan de fortsätta på baksidan.</li>
        <li>Avsluta med en kort presentation i tvärgrupper där varje elev får presentera sina skisser.</li>
        <li>Kan med fördel jobbas vidare med enligt metod <a href='/methods/4.2'>4.2 - empathy mapping.</a></li>
      </ol>

    </MethodLayout>
  )
}
