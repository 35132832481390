import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page13 = () => {

  return (
    <MethodLayout
      title={"1.3 Rapid research"}
      prevNum={1.2}
      nextNum={2.1}
    >
      <b className='methods-layout-content-description'>Ju bättre du förstår ditt problem, desto bättre kan du lösa det. Vi lever i en tidsålder med direkt tillgång till otroliga mängder av information.<br />
        Det ska vi nyttja i denna snabba forskning-session.</b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        groupSizeMin={3}
        groupSizeMax={5}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li> Klargör ett avgränsat problemområde. Ex. hushållning av vattenresurser. Skriv det stort på whiteboarden / projektorn. </li>
        <li> Ge de studerande 15 minuter på sig att skrapa ihop så mycket information som möjligt om problemområdet.
          Alla resurser står till förfogande. Google, FN-rapporter, Bjurholms kommuns vattenrapport etc.
        </li>
        <li>Använd toolie.se/groupie för att dela in klassen i grupper om 3 - 4 pers med en framslumpad gruppledare.</li>
        <li> Ge grupperna 10 min på sig att sammanställa sina efterforskningar i en punktlista.</li>

        <li> Ge grupperna 10 min på sig att placera ut sina punkter på en viktig/svårt-matris (se nedan). <br />

          <figure>
            <img
              src="/images/xychart.svg"
              alt="x-y-chart"
              style={{ marginTop: '2rem' }}
            />
            <figcaption>

            </figcaption>
          </figure>
          <p>x: teknisk svårt</p>
          <p>y: viktigt</p>

        </li>
      </ol>

    </MethodLayout>
  )
}
