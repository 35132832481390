import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page43 = () => {

  return (
    <MethodLayout
      title={"4.3 I like, I wish, I wonder"}
      prevNum={3.3}
      nextNum={undefined}
    >
      <b className='methods-layout-content-description'>
        Feedback är en nyckelfaktor i all innovation och avgörande för den iterativa processen som sker i skav- och plåstermodellens andra “diamant”. <br />
        Denna metod ger innovatörerna snabb och konkret feedback på allt ifrån konceptuella lösningsförslag till konkreta prototyper.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Skriv en lösningsidé stort på whiteboarden / projektorn, ex. <i>en webbtjänst som möjliggör ett låne-ekosystem för vardagsprylar man inte använder så ofta</i>, ex. <i>en bandsåg.</i></li>
        <li>Ge varje elev 3-5 post-its och uppmuntra dem att skriva kortfattat och tydligt.</li>
        <li>Gör tre kolumner på whiteboarden och ge dem rubrikerna: i like, I wish, I wonder.</li>
        <li>Ge eleverna 5 minuter att fylla i sin feedback på post-its med formuleringarna
          <div className='background-white-with-opacity' style={{ display: 'flex', maxWidth: '800px', marginTop: '1rem', marginBottom: '1rem', columnGap: '2rem' }}>
            <ul className='text-decoration-none'>
              <li className='li-row'>
                <b>I like</b>
                <p>Vad som gick bra eller vad som är positivt med en idé.</p>
              </li>
              <li className='li-row'>
                <b>I wish</b>
                <p>Vad som skulle kunna göras annorlunda/förbättras.</p>
              </li>
              <li className='li-row'>
                <b>I wonder</b>
                <p>För frågor som fortfarande är obesvarade och idéer.</p>
              </li>
            </ul>
          </div>
        </li>

        <li>Be eleverna gå fram till whiteboarden och sätta sina post-its i respektive kolumn.</li>
      </ol>

    </MethodLayout>
  )
}
