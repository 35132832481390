import React from "react";
import { CheckCircleFilled, ClockCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { BASE_PROJECT_COLORS } from "./colorConstants";

export function userHasHearted(array, searchFor) {
  return array?.find(x => x === searchFor)
}

export function getHeartedCount(array) {
  return array?.length || 0
}

export function checkRequiredPermissions(user) {
  return user?.email?.endsWith("ntig.se") || user?.email?.endsWith("zocom.se") || user?.email?.endsWith("academedia.se") || false
}

export function daysUntilDateStr(date2) {
  if (new Date(date2) > new Date()) {
    return `${Math.ceil((new Date(date2) - new Date()) / 86400000)} dagar kvar`
  } else {
    return `${Math.ceil((new Date(date2) - new Date()) / 86400000) * -1} dagar sen`
  }
}

export const getLongDate = (epochTime) => {
  const options = { month: 'long', day: 'numeric' }
  return new Date(epochTime).toLocaleDateString('sv-SE', options)
}

export function determineTimeLineParentIcon(date, date2) {

  const dateNow = new Date()
  const sDateFrom = new Date(date)
  const sDateTo = new Date(date2)
  let icon

  if (sDateTo < dateNow) {
    icon = getTimeLineIcon("done")
  }
  else if (dateNow >= sDateFrom && dateNow <= sDateTo) {
    icon = getTimeLineIcon("progressing.now")
  }
  else {
    icon = getTimeLineIcon("planned")
  }

  return icon
}

export function determineTimeLineChildIcon(date, date2) {

  const dateNow = new Date()
  const sDateFrom = new Date(date)
  const sDateTo = new Date(date2)
  let icon

  if (sDateTo < dateNow) {
    icon = getTimeLineIcon("child.done")
  }
  else if (dateNow >= sDateFrom && dateNow <= sDateTo) {
    icon = getTimeLineIcon("child.progressing.now")
  }
  else if (sDateFrom > dateNow) {
    icon = getTimeLineIcon("child.planned")
  }

  return icon
}

export function getTimeLineIcon(key) {
  if (key === 'progressing.now') {
    return <RightCircleFilled className={'Time-line-icon parent-now'} />
  }
  if (key === 'child.progressing.now') {
    return <RightCircleFilled className={'Time-line-icon-child now'} />
  }
  if (key === 'child.progressing') {
    return <RightCircleFilled className={'Time-line-icon-child progress'} />
  }
  if (key === 'planned') {
    return <ClockCircleFilled className={'Time-line-icon planned'} />
  }
  if (key === 'child.planned') {
    return <RightCircleFilled className={'Time-line-icon-child planned'} />
  }
  if (key === 'done') {
    return <CheckCircleFilled className={'Time-line-icon done'} />
  }
  if (key === 'child.done') {
    return <CheckCircleFilled className={'Time-line-icon-child done'} />
  }
  return null
}
