import React from 'react';
import { Timeline } from "antd";
import './TimeLine.css'
import TimeLineJSON from './timeline.json'
import {
  daysUntilDateStr,
  determineTimeLineChildIcon,
  determineTimeLineParentIcon,
  getLongDate,
} from "../../../util/util";
import { SmileFilled } from "@ant-design/icons";
import { AboutProject } from "./AboutProject";

const ContentTimeLine = () => {

  return (
    <div className={'Main-Container-Timeline'}>
      <div className={'Container-Timeline'}>
        <Timeline mode={'left'} style={{ color: 'white' }}>

          {TimeLineJSON.timeline.map((item) => (
            <React.Fragment>
              <Timeline.Item dot={determineTimeLineParentIcon(item.dateFrom, item.dateTo)}>
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', textIndent: 5 }}>
                  <b>{item.title}</b>
                  <i>{getLongDate(item.dateFrom)}</i>
                  <i>{daysUntilDateStr(item.dateFrom)}</i>

                </div>

              </Timeline.Item>

              {item.children?.map((child) => (
                <Timeline.Item dot={determineTimeLineChildIcon(child.dateFrom, child.dateTo)}>
                  <div style={{ display: 'flex', flexDirection: 'column', textIndent: 20 }}>
                    {/*<code>{getLongDate(child.dateTo)}</code>*/}
                    <p>{child.title}</p>
                    <i>{getLongDate(child.dateFrom)}</i>
                    <i>{daysUntilDateStr(child.dateFrom)}</i>
                  </div>
                </Timeline.Item>
              ))
              }

            </React.Fragment>
          ))}
          <Timeline.Item dot={<SmileFilled className={'Time-line-icon now'} onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }} />}>
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', textIndent: 5 }}>
              <b>{TimeLineJSON.timelineEnd.title}</b>
              <i>{new Date(TimeLineJSON.timelineEnd.date).toLocaleDateString()}</i>
              <i>{daysUntilDateStr(TimeLineJSON.timelineEnd.date)}</i>
            </div>

          </Timeline.Item>

        </Timeline>

      </div>
      <AboutProject />
    </div>
  )
}

export default ContentTimeLine;
