import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page31 = () => {

  return (
    <MethodLayout
      title={"3.1 Googles crazy 8’s"}
      prevNum={2.3}
      nextNum={3.2}
    >

      <b className='methods-layout-content-description'>Crazy 8’s är en snabb skissövning som utmanar eleverna att skissa åtta olika idéer på åtta minuter. <br />
        Målet är att tvingas bort från “första bästa”-tänket, vilket sällan är den bästa ideen. Genom att på kort tid skissa ner 8 olika lösningar så tvingas man skapa en mängd olika lösningar på givet problem.</b>

      <p>Vissa elever kan tycka att den här metoden är skrämmande i början, så det är bra att försäkra alla om att det här är grova skisser. De behöver inte vara perfekta eller vackra – de behöver bara kommunicera idén.</p>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <p>Det är också viktigt att komma ihåg att idéerna inte behöver vara bra. Den här övningen handlar om att tysta den inre kritikern och ge våra kreativa impulser utrymme att blomstra.</p>

      <ol>
        <li>Skriv en problemformulering stort på whiteboarden / projektorn, ex. hur kan vi få människor att välja återvunna kläder, möbler och prylar i större utsträckning?</li>
        <li>Ge varje elev ett A4. Detta skall vikas 2 ggr horisontellt och en ggn på mitten vertikalt. Detta ger A4-pappret 8 “rutor”.</li>
        <li>På 8 minuter ska nu de studerande skissa ner 8 olika lösningar på givet problem. Ett problem per “ruta” på pappret.</li>
        <li>Avsluta med en mini-vernissage där samtliga lösningar visas upp.</li>
      </ol>

    </MethodLayout>
  )
}
