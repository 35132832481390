import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseAuth } from "../../../firebase/auth";
import { Button, Popconfirm } from "antd";
import { STATIC_TEXT } from "../../../util/staticTextConstants";
import { authedUser } from "../../../features/firebase/firebaseAuthReducer";

export const AuthSignOutButton = () => {

  const user = useSelector(authedUser)
  const dispatch = useDispatch()

  const handleConfirm = () => {
    FirebaseAuth.signOut(dispatch)
  }

  return (

    user ?
      <Popconfirm
        title={STATIC_TEXT.ASK_LOG_OUT}
        onConfirm={handleConfirm}
        okText={STATIC_TEXT.YES}
        cancelText={STATIC_TEXT.ABORT}
      >
        <Button
          type={'text'}
          onClick={
            () => { }
          }>{STATIC_TEXT.BTN_SIGN_OUT}</Button>

      </Popconfirm>
      : null
  )
}
