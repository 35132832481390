import React, { useEffect } from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { RouterContent } from "./router/RouterContent";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { useDispatch } from "react-redux";
import { actionsFirebaseAuth } from "./features/firebase/firebaseAuthReducer";
import { firestoreAPI } from "./features/firebase/firestoreAPI";
import { BASE_URL } from "./config/config";

function App() {

  const dispatch = useDispatch()

  useEffect(() => {

    // dispatch(mockActionsFirestore.GET_LIST_IDEAS())
    firestoreAPI.attachIdeasSnapshotListener(dispatch)
  }, [])

  return (
    <Router basename={BASE_URL}>

      <div className="App">
        <AuthConsumer dispatch={dispatch} />
        <Header />
        <RouterContent />
      </div>

    </Router>
  );
}

const AuthConsumer = ({ dispatch }) => {

  return (
    <FirebaseAuthConsumer>
      {({ isSignedIn, user, providerId }) => {
        if (isSignedIn === true) {
          dispatch(actionsFirebaseAuth.SET_CURRENT_FIREBASE_USER(JSON.stringify(user)))
        }
      }}
    </FirebaseAuthConsumer>
  )
}

export default App;
