import firebase from "firebase";
import { documentAdded, documentModified, documentRemoved } from "./firestoreIdeaSlice";
import { FIRESTORE_API_RESPONSES } from "./firestoreResponsesAPI";

// const COLLECTION_IDEAS = "ideas"
const COLLECTION_METHODS = "methods"
const COLLECTION_CANDIDATES = "candidates"
const COLLECTION_VARIABLES = "variables"

function getIdeas() {

  return firebase.firestore()
    .collection(COLLECTION_METHODS)
    .get()
    .then((querySnapshot) => {

      const tempArr = []

      querySnapshot.forEach((doc) => {
        const document = doc.data()
        Object.assign(document, { documentId: doc.id })
        tempArr.push(document)
      });

      // console.log('TempArr:', tempArr);

      return tempArr

    })
    .catch((error) => {
      // console.log("Error getting documents: ", error);
    });

}

function attachIdeasSnapshotListener(dispatch) {

  return firebase.firestore()
    .collection(COLLECTION_METHODS)
    .onSnapshot((snapshot) => {

      snapshot.docChanges().forEach((change) => {

        if (change.type === "added") {
          const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
          dispatch(documentAdded(doc))
        }
        if (change.type === "modified") {
          const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
          dispatch(documentModified(doc))
        }
        if (change.type === "removed") {
          const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
          dispatch(documentRemoved(doc))
        }
      });

    }, (error) => {
      // console.log('attachIdeasSnapshotListener::', error)
    });

}


async function setIdea(data) {

  return firebase.firestore()
    .collection(COLLECTION_METHODS)
    .doc()
    .set(data)
    .then(() => {
      return FIRESTORE_API_RESPONSES.SUCCESS_WRITING_DOC
    })
    .catch(() => {
      return FIRESTORE_API_RESPONSES.ERROR_WRITING_DOC
    })

}

async function updateIdea(data) {

  firebase.firestore()
    .collection(COLLECTION_METHODS)
    .doc(data.docId)
    .update(data.updateData)
    .then(() => {
      return FIRESTORE_API_RESPONSES.SUCCESS_UPDATING_DOC
    })
    .catch(() => {
      return FIRESTORE_API_RESPONSES.ERROR_UPDATING_DOC
    })

}

async function getLatestCardNumber() {

  return firebase.firestore()
    .collection(COLLECTION_VARIABLES)
    .doc('var')
    .get()
    .then(doc => {
      return JSON.parse(doc.data()['latest_card_num'])
    })
    .catch((error) => {
      console.error("Error getting /variables/var document: ", error);
    })

}

function incrementLatestCardNumber() {

  return firebase.firestore()
    .collection(COLLECTION_VARIABLES)
    .doc('var')
    .update({ latest_card_num: firebase.firestore.FieldValue.increment(1) })
    .then(() => {
      // console.log("Document card successfully updated");
    })
    .catch((error) => {
      // console.error("Error card updating /variables/var document: ", error);
    })
}

function assignDocumentIdToDoc(documentData, id) {
  Object.assign(documentData, { documentId: id })
  return documentData
}


async function updateCandidate(data) {

  // console.log(data)

  return firebase.firestore()
    .collection(COLLECTION_CANDIDATES)
    .doc(data.docId)
    .set(data.data, { merge: true })
    .then(() => {
      return {
        payload: {
          docId: data.docId
        }
      }
    })
    .catch(() => {
      return FIRESTORE_API_RESPONSES.ERROR_UPDATING_DOC
    })

}
async function getCandidates() {

  return firebase.firestore()
    .collection(COLLECTION_CANDIDATES)
    .get()
    .then((querySnapshot) => {

      const tempArr = []

      querySnapshot.forEach((doc) => {
        const document = doc.data()
        Object.assign(document, { documentId: doc.id })
        tempArr.push(document)
      });

      // console.log('TempArr:', tempArr);

      return tempArr

    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

}

export const firestoreAPI = {
  getIdeas,
  setIdea,
  updateIdea,
  attachIdeasSnapshotListener,
  getLatestCardNumber,
  incrementLatestCardNumber,
  updateCandidate,
  getCandidates
}
