import React from 'react';
import { MethodCard } from '../components'
import { Link } from 'react-router-dom'
import '../styles/methods.css'


const PageMethods = () => {


  return (
    <div className='methods-page'>

      <Link className='download-link background-white-with-corners' to="/pdf/nti-innovationskokbok-2022.pdf" target="_blank" download>Ladda ner innovationskokboken här</Link>

      <h2 className='section-title'>1.0 Upptäck</h2>
      <p className='section-description'>Första fasen av skav- och plåstermodellen går ut på att ta reda på vad problemet är, egentligen. Här är det divergent tänkande som är gällande och det handlar om att hitta så många olika förklaringar och problem inom (an)givet område som möjligt. Upptäckarfasen kan vara ett kort post-it-party, eller en längre research-fas beroende på vilket problem man försöker lösa. Resultatet brukar bli väldigt spretigt och kan till en början kännas svårnavigerad.</p>
      <section className='section-cards'>

        <MethodCard
          num={1.1}
          title={"Brainstorming deluxe"}
          content="Att brainstorma är en klassisk metod för att generera många ideer, men dessvärre är ett lika klassisk misstag är att man gör det i grupp. Här får du en brainstormingsession uppdelat i tre steg som syftar till att höja kvantiteten (och därmed chans till högre kvalitet)"
        />

        <MethodCard
          num={1.2}
          title={"Multiple Perspective Problem Framing"}
          content="Vi människor blir väldigt snabbt “hemmablinda” och får tunnelseende när vi ska förstå och lösa problem. En konkret metod för att kringgå denna begränsning är att ta på sig olika “glasögon” vid granskning av samma problemområde."
        />


        <MethodCard
          num={1.3}
          title={"Rapid research"}
          content="Ju bättre du förstår ditt problem, desto bättre kan du lösa det. Vi lever i en tidsålder med direkt tillgång till otroliga mängder av information. Det ska vi nyttja i denna snabba forskning-session. "
        />

      </section>

      <h2 className='section-title'>2.0 Definiera</h2>
      <p className='section-description'>I andra fasen är konvergent tänkande centralt och här handlar det om att analysera, summera och koka ner resultatet från föregående steg. Målet i denna fas är att skapa en tydlig problemformulering. “alltså, problemet är… “.</p>
      <section className='section-cards'>

        <MethodCard
          num={2.1}
          title={"The 5 whys"}
          content="De fem varför ( 5 whys ) är en beprövad och välanvänd metod för att utifrån en given problemformulering borra ner till roten av problemet, en så kallad rotfelsanalys. Likt en lök skalar varje svar på varför bort ett lager tills vi kommer till kärnan av problemet. Att identifiera roten till problemet hjälper till att göra något åt orsaken och därmed åtgärda felet, istället för att enbart ta hand om symptomen"
        />

        <MethodCard
          num={2.2}
          title={"Future pressrelease"}
          content="Följande metod används av Google, Amazon och Microsoft för att koka ner och formulera ett problemområde. Ett framtida pressmeddelande kan hjälpa till att fokusera på det önskade resultatet och få alla i gruppen på samma spår. För att börja skriva pressmeddelandet, svara på dessa frågor"
        />

        <MethodCard
          num={2.3}
          title={"GIF Story time"}
          content="Människan älskar historier. De får oss att förstå problem och människor på ett sätt som . Det nyttjar vi i denna metod där du med stöd av GIF:ar ska summera och förklara problemet. Som hjälp att skriva en kort story som förklarar din problemformulering på ett bra och tydligt sätt är de 3 P:na"
        />
      </section>

      <h2 className='section-title'>3.0 Utveckla</h2>
      <p className='section-description'>Inte förrän du har en god insikt om vad problemet är, och beror på, är det möjligt att på ett bra sätt lösa det. I denna tredje fas är det återigen divergent tänkande som gäller och här är fokus att komma på så många olika lösningar på givet problem som möjligt. </p>
      <section className='section-cards'>

        <MethodCard
          num={3.1}
          title={"Googles crazy 8’s"}
          content="Crazy 8´s är en snabb skissövning som utmanar eleverna att skissa åtta olika idéer på åtta minuter. Målet är att tvingas bort från “första bästa”-tänket, vilket sällan är den bästa ideen. Genom att på kort tid skissa ner 8 olika lösningar så tvingas man skapa en mängd olika lösningar på givet problem."
        />

        <MethodCard
          num={3.2}
          title={"Reversed brainstorming"}
          content="Omvänd brainstorming drar fördel av den naturliga mänskliga tendensen att lättare se problem än lösningar. Dessutom kommer många individer när de placeras i en traditionell brainstormingmiljö att ha svårt att bli kreativa på kommando. Omvänd brainstorming är en metod som går ut på att försöka göra problemet värre, och sen vända på det."
        />

        <MethodCard
          num={3.3}
          title={"Six cats"}
          content="De sex katterna symboliserar olika sätt att tänka, och ska användas genom att man fokuserar på ett problem från sex olika perspektiv. De sex katterna är en remix av klassiska “de Bonos 6 tänkarhattar”."

        />
      </section>

      <h2 className='section-title'>4.0 Leverera</h2>
      <p className='section-description'>I sista fasen är fokus på att välja ut, realisera, samt testa någon (eller fler) av de lösningar som har bäst förutsättningar för att lyckas från steget innan. Här måste man välja och prioritera. Leveransfasen är ofta cyklisk där det handlar om att bygga, testa, utvärdera och justera tills önskat resultat är uppnått.</p>
      <section className='section-cards'>

        <MethodCard
          num={4.1}
          title={"Paper prototyping"}
          content="Pappersprototyper är ett utmärkt verktyg för att utveckla idéer och designa användarflöden med hjälp av pappersskisser. Metoden lämpar sig extra bra för digitala produkter, men även design av fysiska produkter går. Pappersprototypers syfte är att fånga en känsla för någonting och att enkelt kunna få feedback gällande användarupplevelsen."
        />

        <MethodCard
          num={4.2}
          title={"Empathy mapping"}
          content="En empatikarta är ett visualiseringsverktyg som används för att kartlägga (map) vad vi vet om användare när de använder vår produkt / tjänst. Metoden ger en djupare förståelse för “varför“-aspekten bakom användarnas behov och önskemål."
        />

        <MethodCard
          num={4.3}
          title={"I like, I wish, I wonder"}
          content="Feedback är en nyckelfaktor i all innovation och avgörande för den iterativa processen som sker i skav- och plåstermodellens andra “diamant”. Denna metod ger innovatörerna snabb och konkret feedback på allt ifrån konceptuella lösningsförslag till konkreta prototyper."
        />
      </section>
    </div>
  )
}
export default PageMethods;
