import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page11 = () => {

  return (
    <MethodLayout
      title={"1.1 Brainstorming deluxe"}
      prevNum={undefined}
      nextNum={1.2}
    >

      <b className='methods-layout-content-description'>
        Att brainstorma är en klassisk metod för att generera många ideer, men dessvärre är ett lika klassisk misstag är att man gör det i grupp. Här får du en brainstormingsession uppdelat i tre steg som syftar till att höja kvantiteten (och därmed chans till högre kvalitet).
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Klargör ett problemområde, litet som stort. Ex. något av FNs klimatmål. Skriv det stort på whiteboarden/projektorn.</li>
        <li>Dela du ut en ordentlig bunt post-it lappar till varje elev, och gå igenom innovationens grundprinciper (se presentationsmall).</li>
        <li>På 5 minuter ska eleverna nu komma på så många olika problem inom problemområdet. De ska nedteckna ett problem per post-it.</li>
        <li>Efter första individuella stormningen ska de sättas i grupper om 3 - 5 pers. Här ska de nu sätta alla post-its på bordet / väggen.</li>
        <li>Nu får gruppen 15 minuter på sig att gå igenom varje post-it och börja hitta gemensamma drag. Målet är att ”klumpa ihop” relaterade post-its och på så sätt förstå problemområdet bättre. Varje grupp av post-its skall ges en tydlig titel.</li>
        <li>Vid tid kan steg 5 även göras i helklass.</li>
      </ol>

    </MethodLayout >
  )

}
