import firebase from "firebase/app";
import { actionsFirebaseAuth } from "../../features/firebase/firebaseAuthReducer";
// import * as firebaseui from 'firebaseui/dist/npm__sv';
import * as firebaseui from 'firebaseui';
import { firebaseAuth } from "../firebaseConfig";
import './firebase-ui.css'
import { FIREBASE_AUTH_PRIVACY_POLICY_URL, FIREBASE_AUTH_TERMS_OF_SERVICE_URL } from "../../config/config";

function googleSignInPopup(dispatch) {

  let provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    'login_hint': 'user@ngit.se',
    'prompt': 'select_account'
  });

  firebase.auth()

    .signInWithPopup(provider)
    .then((result) => {
      // let credential = result.credential;
      let user = result.user;

      // console.log('Google Auth User', userData);
      dispatch(actionsFirebaseAuth.SET_CURRENT_FIREBASE_USER(user ? JSON.stringify(user) : null))

    }).catch((error) => {

      dispatch(actionsFirebaseAuth.SET_CURRENT_FIREBASE_USER(null))
      console.log('Sign in error with Google occurred', error)

    });

}

const uiStart = (dispatch, navigate) => {

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult) {
        let user = authResult.user;
        dispatch(actionsFirebaseAuth.SET_CURRENT_FIREBASE_USER(user ? JSON.stringify(user) : null))
        navigate(-1)
        return true;
      },
      uiShown: function () {
        // document.getElementById('loader').style.display = 'none';
      }
    },
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          'prompt': 'select_account'
        },
        fullLabel: 'Logga in med Google',
        providerName: 'Google',
        buttonColor: 'white',
        iconUrl: "",
        // defaultCountry: 'SV'
      }
    ],
    tosUrl: FIREBASE_AUTH_TERMS_OF_SERVICE_URL,
    privacyPolicyUrl: FIREBASE_AUTH_PRIVACY_POLICY_URL
  }

  let ui = firebaseui.auth.AuthUI.getInstance() ? firebaseui.auth.AuthUI.getInstance() : new firebaseui.auth.AuthUI(firebaseAuth);
  ui.start('#firebaseui-auth-container', uiConfig);

}


const isSignedIn = () => {
  return !(firebase.auth().currentUser)
}


function signOut(dispatch) {
  firebase.auth().signOut().then(
    dispatch(actionsFirebaseAuth.SET_CURRENT_FIREBASE_USER(null))
  )
}

function currentUser() {
  return firebase.auth().currentUser
}

export const FirebaseAuth = { googleSignInPopup, isSignedIn, currentUser, signOut, uiStart }
