import React from 'react';
import UserProfile from "../components/User/Profile/UserProfile";

const ProfilePage = () => {

  return (
     <React.Fragment>
       <UserProfile/>
     </React.Fragment>
  )
}
export default ProfilePage;
