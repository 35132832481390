import React, { useState } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authedUser } from "../../features/firebase/firebaseAuthReducer";
import {
  clearDocumentCreateStatus,
  createIdea,
  createIdeaUploadStatus
} from "../../features/firebase/firestoreIdeaSlice";
import { IdeaNew } from "../../firebase/firestore/data/IdeaNew";
import { ResultError, ResultSuccess, ResultUserEmailPermissionDenied } from "../../firebase/notifications";
import { BASE_PROJECT_COLORS as COLORS } from "../../util/colorConstants";
import { STATIC_TEXT as lang, STATIC_TEXT } from "../../util/staticTextConstants";
import { checkRequiredPermissions } from "../../util/util";
import { MoveToAuthSignInButton } from "../firebase/MoveToAuthSignInButton";
import { InputEmail } from "../SharedComponents/Input/InputEmail";
import { InputTitleIdea } from "../SharedComponents/Input/InputTitleIdea";
import { TextAreaIdea } from "../SharedComponents/Input/TextAreaIdea";
import './FormIdea.css';

export const FormIdea = () => {

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const user = useSelector(authedUser)
  const uploadState = useSelector(createIdeaUploadStatus);
  const [isUploading, setIsUploading] = useState(false)
  const navigate = useNavigate()

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values) => {
    const idea = IdeaNew(values.title, values.text, user.email, user.uid)
    // dispatch(mockActionsFirestore.ADD_IDEA(idea))
    setIsUploading(true)
    dispatch(createIdea(idea)).then(() => {
      setIsUploading(false)
    })
  };

  return checkRequiredPermissions(user) ?
    <div className={'Form-container'}>
      {
        uploadState.type === "IDLE" ?
          <Form
            form={form}
            name="form_idea"
            initialValues={{ remember: false, }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <InputEmail email={user?.email} />
            <InputTitleIdea useClass={'Input-title-idea'} initialValue={''} disabled={false} />

            <Divider />
            {/*<InputTags TAGS={TAGS} form={form} initialValue={[]}/>*/}
            {/*<Divider style={{marginTop: 5, marginBottom: 5}}/>*/}

            <Col span={24}>
              <TextAreaIdea useClass={'Input-text-area-idea'} initialValue={''} />
            </Col>

            <Divider />
            <ButtonSubmit isUploading={isUploading} />

          </Form>
          : (uploadState.type === "SUCCESS")
            ? <ResultSuccess
              title={STATIC_TEXT.SUCCESS_PUBLISHED_IDEA}
              subTitle={STATIC_TEXT.SUCCESS_PUBLISHED_IDEA_SUB_TITLE}
              btnPrimaryText={STATIC_TEXT.NEW_IDEA}
              btnSecondaryText={STATIC_TEXT.TO_LIST}
              onButtonPrimaryClick={() => {
                form.resetFields()
                dispatch(clearDocumentCreateStatus())
              }}
              onButtonSecondaryClick={() => {
                form.resetFields()
                dispatch(clearDocumentCreateStatus())
                navigate('/')
              }}
            />
            : <ResultError
              title={STATIC_TEXT.AN_ERROR_OCCURRED_TITLE}
              subTitle={STATIC_TEXT.AN_ERROR_OCCURRED_SUB_TITLE}
              btnPrimaryText={STATIC_TEXT.BACK_TO_FORM}
              onButtonPrimaryClick={() => {
                dispatch(clearDocumentCreateStatus())
              }}
            />
      }
    </div>
    : !user ? <MoveToAuthSignInButton /> : <ResultUserEmailPermissionDenied
      title={lang.PERMISSION_DENIED}
      subTitle={lang.ASK_INTERESTED_IN_THE_PROJECT}
      extras={[
        <p style={{ color: 'white', maxWidth: 400 }}>{lang.INTERESTED_LINK_BELOW_TEXT}</p>,
        <a className={'App-link-external'} href={'https://academedia.se/jobba-hos-oss/lediga-jobb/'}
          target={'_blank'}>{'Intresseanmälan'}</a>,
      ]}
    />

}

const ButtonSubmit = ({ isUploading }) => {

  return (
    <Row gutter={24} align={'end'}>
      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          loading={isUploading}
          style={{ color: COLORS.DARK, margin: 15, borderRadius: 2 }}
          size={'large'}
        >
          {isUploading ? STATIC_TEXT.LABEL_SENDING : STATIC_TEXT.LABEL_SEND}

        </Button>
      </Form.Item>
    </Row>
  )

}


export default FormIdea;
