import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/green-tech-challenge.css'

const PageGreenTechChallenge = () => {

  return (
    <div className='green-tech-page'>
      <h2 className='section-title'>NTI Green Tech Challenge</h2>
      
      <p className='section-description'>NTI Gymnasiets ledord “Framtidens innovationer börjar här” ska genomsyra alla våra utbildningar oavsett vilket program eller vilken skola våra elever har valt att studera på. Som elev hos oss ska du förutom starka ämneskunskaper också vara en mästare på innovationsarbete.</p>
      <a className="link" href="/timeline">Se vår tidslinje för när olika delar av projektet ska vara klart</a>
      <p>I och med att vi lever i en snabbt föränderlig värld så ställs det nya krav på våra elever att snabbt kunna lösa nya problemställningar som uppkommer i vårt samhälle. Många av dagens utmaningar har med klimatförändring och omställning att göra. Som elev på NTI är målet att du genom din kunskap om den senaste teknologin ska vara en mästare på att komma fram till innovativa lösningar på dagens och morgondagens utmaningar. Vi vet att framtiden ligger inom fältet GREEN TECH och att våra elever är de som är nyckeln till att vi ska fortsätta att kunna befolka vår jord. Inte minst är det helt avgörande kompetens för ett framtida yrkesliv inom TECH-branschen.</p>
      <p>NTI GREEN TECH CHALLENGE riktar sig till samtliga våra NTI-elever oavsett program och kurs.</p>
     
      <p>Exempel på kurser</p>
      <ul>
        <li>Gymnasiearbete</li>
        <li>Teknik 1</li>
        <li>Tillämpad programmering</li>
        <li>TE4s Gymnasiearbete</li>
      </ul>
      <p>Fungerar det inte med någon kurs, så kan man ha temadagar som riktar sig mot green tech.</p>
      
      <section className='challenge'>
        <h3>Utmaning</h3>
        <p><strong>Du ska hitta en teknisk och/eller digital lösning på ett problem relaterat till de globala målen.</strong></p>
        <p><em>I år har vi särskilt fokus
på följande mål:</em></p>
        <ul>
          <li>7. Hållbar energi för alla</li>
          <li>9. Hållbar industri, innovationer och infrastruktur</li>
          <li>11. Hållbara städer och samhällen</li>
          <li>12. Hållbar konsumtion och produktion</li>
          <li>13. Bekämpa klimatförändringarna</li>
        </ul>
        <a href="https://www.globalamalen.se" target="_blank">Länk till globala målen</a>
      </section>

      <h3 className='section-title-sml'>Krav på innovationsbidrag i NTI Green Tech Challenge</h3>
      <ul>
        <li>En eller flera digitala/tekniska prototyper</li>
        <li>En fullt funktionell prototyp</li>
        <li>Lösningen ska fokusera på ert lokala område (Think Global, Act Local)</li>
        <li>Processdokumentation med en tydlig beskrivning av verktyg som använts för att gå från idé till färdig prototyp</li>
        <li>Pitch/idébeskrivning (5 min)</li>
      </ul>
      
      <h3 className='section-title-sml'>Bedömningskriterier</h3>
      <p><em>Din/er innovation ska:</em></p>
      <ul>
        <li>Ska vara nytt. Konceptet är unikt.</li>
        <li>Ska göra nytta. Innovationen bidrar till mätbar positiv effekt.</li>
        <li>Innovationen har stor potential för att bli nyttiggjord.</li>
      </ul>
      <Link className='download-link background-white-with-corners' to="/pdf/NTI-Green-Tech-Challenge-2023.pdf" target="_blank" download>Ladda ner NTI Green Tech Challenge</Link>
    </div>
  )
}
export default PageGreenTechChallenge;
