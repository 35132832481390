import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page42 = () => {

  return (
    <MethodLayout
      title={"4.2 Empathy mapping"}
      prevNum={4.1}
      nextNum={4.3}
    >

      <b className='methods-layout-content-description'>
        En empatikarta är ett visualiseringsverktyg som används för att kartlägga (map) vad vi vet om användare när de använder vår produkt / tjänst. Metoden ger en djupare förståelse för "varför"-aspekten bakom användarnas behov och önskemål.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Utgå ifrån någon av era pappersprototyper från metod 4.1 - paper prototyping. Välj ut ett mål med interaktionen, ex. “logga in”, eller “ställa in X på produkten”.</li>
        <li>Utsätt 2-3 klasskamrater för dina skisser. Ditt mål som testobservatör är att ta reda på vad användaren
          <div className='background-white-with-opacity' style={{ display: 'flex', maxWidth: '800px', marginTop: '1rem', marginBottom: '1rem', columnGap: '2rem' }}>
            <ul className='text-decoration-none'>
              <li className='li-row'>
                <b>Say</b>
                <p>Vad användaren säger om produkten.</p>
              </li>
              <li className='li-row'>
                <b>Think</b>
                <p>Vad tänker användaren på när hen interagerar med er prototyp? Vad är viktigt för användaren?</p>
              </li>
              <li className='li-row'>
                <b>Feel</b>
                <p>Vad oroar användaren? Vad blir användaren pepp över? Hur upplever användaren upplevelsen?</p>
              </li>
              <li className='li-row'>
                <b>Do</b>
                <p>Vilka åtgärder vidtar användaren? Vilka handlingar och beteenden märkte du?</p>
              </li>
            </ul>
          </div>
        </li>
        <li>Efter du utfört 2-3 olika empatikartor så sammanställer du dina insikter. Vilka likheter finns i användarnas beteenden, tankar och känslor?</li>
      </ol>


    </MethodLayout>
  )
}
