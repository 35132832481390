export const BaseCard = ({ className, header, content, footer, onClick }) => {

  return (
    <div
      // style={{ display: 'flex', flexDirection: 'column', padding: 15, backgroundColor: 'white', borderRadius: 2 }}
      className={className}
      onClick={onClick}
    >
      {header && (
        <div style={{ display: 'flex', width: '100%', marginBottom: 10 }}>
          {header}
        </div>
      )}
      {content}
      {footer && (
        <div style={{ display: 'flex', width: '100%', marginTop: 10 }}>
          {footer}
        </div>
      )}
    </div>
  )
}