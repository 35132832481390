import { STATIC_TEXT } from "../util/staticTextConstants";
import PageIdea from "../pages/PageIdea";
import PageForm from "../pages/PageForm";
import { UserOutlined } from "@ant-design/icons";
import ProfilePage from "../pages/ProfilePage";
import React from "react";
import { AuthSignIn } from "../components/fb/AuthSignIn";
import PageTimeLine from "../pages/PageTimeLine";
import PageMethods from '../pages/methods';
import { Page11 } from '../pages/methods/1-1';
import { Page12 } from '../pages/methods/1-2';
import { Page13 } from '../pages/methods/1-3';
import { Page21 } from '../pages/methods/2-1';
import { Page22 } from '../pages/methods/2-2';
import { Page23 } from '../pages/methods/2-3';
import { Page31 } from '../pages/methods/3-1';
import { Page32 } from '../pages/methods/3-2';
import { Page33 } from '../pages/methods/3-3';
import { Page41 } from '../pages/methods/4-1';
import { Page42 } from '../pages/methods/4-2';
import { Page43 } from '../pages/methods/4-3';
import PageGreenTechChallenge from '../pages/green-tech-challenge';
import PageVideo from '../pages/video';


const methodsRoutes = [
  {
    path: '1.1',
    Component: <Page11 />
  },
  {
    path: '1.2',
    Component: <Page12 />
  },
  {
    path: '1.3',
    Component: <Page13 />
  },
  {
    path: '2.1',
    Component: <Page21 />
  },
  {
    path: '2.2',
    Component: <Page22 />
  },
  {
    path: '2.3',
    Component: <Page23 />
  },
  {
    path: '3.1',
    Component: <Page31 />
  },
  {
    path: '3.2',
    Component: <Page32 />
  },
  {
    path: '3.3',
    Component: <Page33 />
  },
  {
    path: '4.1',
    Component: <Page41 />
  },
  {
    path: '4.2',
    Component: <Page42 />
  },
  {
    path: '4.3',
    Component: <Page43 />
  },
]


export const routes = [
  {
    path: '/',
    name: STATIC_TEXT.NAV_IDEAS,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageIdea />,

  },
  // {
  //   path: '/winners',
  //   name: STATIC_TEXT.CANDIDATES,
  //   navBarDisplay: {
  //     visible: true,
  //     iconOnly: false,
  //     icon: null,
  //   },
  //   exact: true,
  //   requiresSignIn: false,
  //   Component: <PageCandidates/>
  // },
  {
    path: '/methods',
    name: STATIC_TEXT.NAV_METHODS,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageMethods />,
    requiresAuth: true,
    childRoutes: methodsRoutes
  },
  {
    path: '/timeline',
    name: STATIC_TEXT.ABOUT_US,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageTimeLine />,
  },
  {
    path: '/form-idea',
    name: STATIC_TEXT.NAV_FORM,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageForm />
  },
  {
    path: '/green-tech-challenge',
    name: STATIC_TEXT.NAV_GREEN_TECH,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageGreenTechChallenge />
  },
  {
    path: '/ntig-online',
    name: STATIC_TEXT.NAV_NTIG_ONLINE,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    requiresAuth: true,
    Component: <PageVideo />
  },
  {
    path: '/profile',
    name: STATIC_TEXT.NAV_PROFILE,
    navBarDisplay: {
      visible: true,
      iconOnly: true,
      icon: <UserOutlined className={'Nav-profile-button'} />,
    },
    exact: true,
    Component: <ProfilePage />
  },
  {
    path: '/not-signed-in',
    name: STATIC_TEXT.NAV_NOT_SIGNED_IN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <AuthSignIn />
  },
]
