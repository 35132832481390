import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import '../styles/video.css'


const PageVideo = () => {

  return (
    <div className='video-page'>
      <h2 className="section-title">NTIG Online 1</h2>
      <p>En inspelad föreläsning med Maria Strömme.</p>
      <Vimeo
  video="750290920"
  responsive={true}
  autoplay
/>
    </div>
  )
}
export default PageVideo;
