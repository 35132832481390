export const FIRESTORE_API_RESPONSES = {
  DEFAULT_STATE: {
    type: 'IDLE',
    message: 'IDLE'
  },
  SUCCESS_WRITING_DOC: {
    type: 'SUCCESS',
    message: 'SUCCESS_WRITING_DOC'
  },
  ERROR_WRITING_DOC: {
    type: 'ERROR',
    message: 'ERROR_WRITING_DOC'
  },
  SUCCESS_UPDATING_DOC: {
    type: 'SUCCESS',
    message: 'SUCCESS_UPDATING_DOC'
  },
  ERROR_UPDATING_DOC: {
    type: 'ERROR',
    message: 'ERROR_UPDATING_DOC'
  }
}
