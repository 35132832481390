import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page33 = () => {

  return (
    <MethodLayout
      title={"3.3 Six cats"}
      prevNum={3.2}
      nextNum={4.1}
    >
      <b className='methods-layout-content-description'>
        De sex katterna symboliserar olika sätt att tänka, och ska användas genom att man fokuserar på ett problem från sex olika perspektiv. De sex katterna är en remix av klassiska “de Bonos 6 tänkarhattar”.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Skriv en problemformulering stort på whiteboarden / projektorn, ex. <i>hur får vi svenskarna att äta mer lokalproducerad mat?</i>.</li>
        <li>Använd toolie.se/groupie för att dela in klassen i grupper om 6 pers. Varje person i gruppen tilldelas en av katterna.</li>
        <li>Grupperna får 15 min på sig att brainstorma lösningar på den givna frågor. De olika katterna ska hålla sina roller. Blå katt för anteckningar.</li>
        <li>Avsluta med en kort presentation från varje grupp där de redogör för sina lösningar.</li>
      </ol>

      <figure>
        <img
          src="https://images.squarespace-cdn.com/content/v1/5b75a2b5620b85a1a78a5594/1567702808128-1F2GFOF9STAYOLO3TTWF/six+thinking+cats.png"
          alt="six-thinking-cats"
          style={{ width: '100%', maxWidth: 600, marginTop: '2rem' }}
        />
        <figcaption>Bildkälla: agility11</figcaption>
      </figure>

    </MethodLayout>
  )
}
