import React from 'react';
import { useSelector } from "react-redux";
import { authedUser } from "../../../features/firebase/firebaseAuthReducer";
import { Tag } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { AuthSignOutButton } from "../../firebase/auth/AuthSignOutButton";
import { MoveToAuthSignInButton } from "../../firebase/MoveToAuthSignInButton";

const UserProfile = () => {

  const user = useSelector(authedUser)

  return (
    user ?
      <div className={'Card'} style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', gap: '0.3rem' }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 15, marginTop: 15 }}>

          <Avatar
            shape="square"
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={user.photoURL}
          />

        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
          <Tag>{user.email}</Tag>
          <Tag>{user.displayName}</Tag>
        </div>

        <AuthSignOutButton />
        {/*<AuthDeleteAccountButton/>*/}
      </div>
      : <MoveToAuthSignInButton />
  )
}
export default UserProfile;
