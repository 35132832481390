import React from 'react';
import FormIdea from "../components/Forms/FormIdea";


const PageForm = () => {

  return (
     <React.Fragment>
       <FormIdea/>
     </React.Fragment>
  )

}

export default PageForm;
