import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";

const initialState = {
  LIST_CANDIDATES: [],
};

export const updateCandidate = createAsyncThunk(
   'firestoreCandidatesSlice/updateCandidate',
   async (data) => {

     // await firestoreAPI.updateCandidate()
     return await firestoreAPI.updateCandidate(data)

   }
);

export const getCandidates = createAsyncThunk(
   'firestoreCandidatesSlice/getCandidates',
   async () => {
     return await firestoreAPI.getCandidates()
   }
);

export const firestoreCandidatesSlice = createSlice({
  name: 'firestoreCandidatesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
       .addCase(getCandidates.pending, (state) => {

       })
       .addCase(getCandidates.fulfilled, (state, action) => {
         console.log('SUCCESS:::', action.payload)
         state.LIST_CANDIDATES = action.payload;

       })
       .addCase(getCandidates.rejected, (state, action) => {
         // state.DOCUMENT_CREATE_STATUS = action.payload;
       })
       .addCase(updateCandidate.pending, (state) => {

       })
       .addCase(updateCandidate.fulfilled, (state, action) => {
         console.log('SUCCESS:::', action.payload)
         // getCandidate(action.payload.docId)
       })
       .addCase(updateCandidate.rejected, (state, action) => {
         // state.DOCUMENT_CREATE_STATUS = action.payload;
       })
  },

});

export const {} = firestoreCandidatesSlice.actions;
export const listOfCandidates = (state) => state.firestoreCandidates.LIST_CANDIDATES;

export default firestoreCandidatesSlice.reducer;
