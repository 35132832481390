import {createAction, createReducer} from "@reduxjs/toolkit";

const SET_CURRENT_FIREBASE_USER = createAction('set current user');

const actionsFirebaseAuth = {
  SET_CURRENT_FIREBASE_USER,
};

const initialState = {
  CURRENT_USER: null,
}

const firebaseAuthReducer = createReducer(initialState, {
  [SET_CURRENT_FIREBASE_USER]: (state, action) => {

    const userAuthInfo = JSON.parse(action.payload)
    let userData = null

    if(userAuthInfo){
      userData = {
          uid: userAuthInfo.uid,
          displayName: userAuthInfo.displayName,
          photoURL: userAuthInfo.photoURL,
          signUpDate: userAuthInfo.signUpDate,
          email: userAuthInfo.email
      }
    }
    return {
      ...state,
      CURRENT_USER: userData
    }

  },
})


export {actionsFirebaseAuth, firebaseAuthReducer};
export const authedUser = (state) => state.firebaseAuth.CURRENT_USER;
