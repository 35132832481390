import firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth()
// firebaseAuth.languageCode = "SV"
export const firestoreDb = firebase.firestore();

// if (process.env.NODE_ENV === 'development') {
//   console.log('EMULATORS IS IN USE::: AUTH & FIRESTORE')
//   firestoreDb.useEmulator("localhost", 8080);
//   firebaseAuth.useEmulator("http://localhost:9099");
// }
