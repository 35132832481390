import React from 'react';
import ContentTimeLine from "../components/StaticContent/TimeLine/ContentTimeLine";


const PageForm = () => {

  return (
     <React.Fragment>
       <ContentTimeLine/>
     </React.Fragment>
  )

}

export default PageForm;
