export const BASE_ICON_COLORS = {
  // heart: "#EB2F96",
  heart: "#059b92",
  chat_blue: "#0088B7",
  unmarked: 'rgb(206,186,186)',
}

export const FORM_STATIC_TEXT = {
  LABEL_EMAIL_ADDRESS: "E-post",
  LABEL_IDEA: "Idé",
  LABEL_TITLE: "Titel",
  LABEL_TAGS: "Taggar",
  PLACEHOLDER_EMAIL_ADDRESS: "Ange din e-post",
  PLACEHOLDER_TITLE: "Ange en titel",
  PLACEHOLDER_IDEA: "Ange din idé",
  PLACEHOLDER_IDEA_EDIT: "Justera din idé",
  PLACEHOLDER_TAGS: "Ange taggar",
  LABEL_SUFFIX_EMAIL: "@ntig.se"
}

export const TAGS = [
  {
    type: "LESSION",
    displayText: "Lektion"
  },
  {
    type: "CLASSROOM",
    displayText: "Klassrum"
  },
  {
    type: "NOTES",
    displayText: "Anteckningar"
  }
]
