export const STATIC_TEXT = {
  COMMENT: "Kommentera",
  LIKE: "Gilla",
  NAV_FORM: "Formulär",
  NAV_GREEN_TECH: "NTI Green Tech Challenge",
  NAV_NTIG_ONLINE: "NTIG Online",
  NAV_PROFILE: "Profil",
  NAV_METHODS: "Innovationskokboken",
  NAV_IDEAS: "Idéer",
  NAV_NOT_SIGNED_IN: "Inte inloggad",
  ABOUT_US: "Om",
  CANDIDATES: "Vinnare",
  CHAFING: "Skav",
  BAND_AID: "Plåster",
  EDUCATIONAL_WINNINGS: "Pedagogiska vinster",
  BTN_SIGN_OUT: 'Logga ut',
  BTN_REMOVE_ACCOUNT: 'Ta bort konto',
  BTN_SIGN_IN: 'Logga in',
  BTN_JOIN_TEAM: 'Gå med i team',
  BTN_LEAVE_TEAM: 'Gå ur team',
  BTN_SIGN_IN_JOIN_TEAM_REQ: 'Logga in för att gå med i team',
  LABEL_SEND: 'Skicka',
  LABEL_SENDING: 'Skickar',
  ASK_LOG_OUT: 'Är du säker på att du vill logga ut?',
  ASK_DELETE_ACCOUNT: 'Är du säker på att du vill ta bort konto och uppgifter? detta går inte att ångra sedan!',
  YES: 'Ja',
  NO: 'Nej',
  ABORT: 'Avbryt',
  ASK_INTERESTED_IN_THE_PROJECT: 'Intresserad av att vara med i projektet?',
  INTERESTED_LINK_BELOW_TEXT: 'Skicka gärna in en intresseanmälan via länken nedan',
  DELETE: 'Ta bort',
  SAVE: 'Spara',
  TO_LIST: "Till listan",
  NEW_IDEA: "Ny idé",
  MODAL_TITLE_ADJUST_IDEA: "Justera idé",
  SUPPLY_AN_IDEA: "Ange en ny idé",
  BACK_TO_FORM: "Tillbaka till formulär",
  AN_ERROR_OCCURRED_TITLE: "Ett fel inträffade",
  AN_ERROR_OCCURRED_SUB_TITLE: "Vänligen gå tillbaka och försök igen. Uppstår felet igen, försök vid ett senare tillfälle.",
  SUCCESS_PUBLISHED_IDEA: "Tack för din idé!",
  PERMISSION_DENIED: "Tillgång saknas",
  SUCCESS_PUBLISHED_IDEA_SUB_TITLE: "Din idé har blivit publicerad",
  CONTINUE: "Fortsätt",
  SIGN_IN_REQUIRED: "Inloggning krävs",
  SIGN_IN_REQUIRED_DESCRIPTION: "Vänligen tryck på knappen nedan för att fortsätta till inloggnings-sidan",
  NOTIFICATION_NOT_SIGNED_IN_TITLE: "Inte inloggad",
  NOTIFICATION_NOT_SIGNED_IN_TEXT: "Vänligen logga in för att använda denna funktion!",
  CREATE_ACCOUNT_NOT_SIGNED_IN_TITLE: 'Skapa konto / logga in',
  CREATE_ACCOUNT_NOT_SIGNED_IN_TEXT: "Här krävs inloggning. Vänligen logga in eller skapa ett konto för att kunna skicka in en idé via formuläret.",
  CREATE_ACCOUNT_NOT_SIGNED_IN_PROFILE_TEXT: "Vänligen logga in att kunna visa din profil här."
}

export const TOOL_TIP_TEXT = {
  FORM_IDEA_TITLE: "Ange en passande titel",
  FORM_IDEA_EMAIL: "Din e-post",
  FORM_IDEA_TAGS: "Taggar som passar din idé",
  FORM_IDEA_TEXT: "Ange din prototyp-idé",
  BTN_FILTER_HEARTED: "Filtrera gillade",
  BTN_FILTER_HEARTED_CLEAR: "Rensa filtrering",
  FORM_IDEA_SEND: 'Skicka',
}

export const FORM_FIELDS_MESSAGE = {
  MISSING_TITLE: "Titel saknas!",
  MISSING_EMAIL: "E-post saknas!",
  MISSING_TAGS: "Minst EN tag krävs",
  MISSING_IDEA: "Du måste ange en idé!"
}
