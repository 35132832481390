import { Button, Col, Form, Popconfirm } from "antd";
import React from "react";
import { STATIC_TEXT } from "../../util/staticTextConstants";
import './Ideas.css'
import { BASE_PROJECT_COLORS as COLORS } from "../../util/colorConstants";
import { useDispatch } from "react-redux";
import Modal from "antd/es/modal/Modal";
import { TextAreaIdea } from "../SharedComponents/Input/TextAreaIdea";
import { updateIdea } from "../../features/firebase/firestoreIdeaSlice";

export const ModalFormEditIdea = ({ modalEditVisible, displayModalIdeaEdit, item }) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const deleteConfirm = () => {

    // dispatch(
    //    mockActionsFirestore.REMOVE_IDEA({ id: item.id })
    // )
    displayModalIdeaEdit()
  }

  const DeleteConfirmElement = () => {
    return (
      <Popconfirm
        title="Är du säker på att du vill ta bort denna idé?"
        onConfirm={() => {
          //deleteConfirm
        }}
        onCancel={() => {
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button danger key="delete" type="link">
          {STATIC_TEXT.DELETE}
        </Button>
      </Popconfirm>
    )
  }

  const onFinish = (values) => {

    //console.log(values)
    // const currentDocData = {...item}
    // Object.assign(currentDocData, { text: values?.text})
    // console.log(currentDocData)
    // dispatch(
    //    mockActionsFirestore.UPDATE_IDEA({id: item.id, ...currentDocData})
    // )

    const newDocData = {
      text: values.text
    }

    dispatch(
      updateIdea(
        {
          docId: item.documentId,
          updateData: newDocData
        }
      )
    )
    displayModalIdeaEdit()
  };

  return (
    <React.Fragment>
      <Modal
        title={STATIC_TEXT.MODAL_TITLE_ADJUST_IDEA}
        centered
        visible={modalEditVisible}
        onOk={displayModalIdeaEdit}
        // width={1000}
        onCancel={displayModalIdeaEdit}
        footer={[
          // <DeleteConfirmElement/>,
          <Button key="cancel" type="link" style={{ color: COLORS.DARK_GRAY }} onClick={displayModalIdeaEdit}>
            {STATIC_TEXT.ABORT}
          </Button>,
          <Button key="submit" type="link" style={{ color: COLORS.DARK }}
            onClick={() => onFinish(form.getFieldsValue())}>
            {STATIC_TEXT.SAVE}
          </Button>,
        ]}
      >
        <div className={'Modal-form-idea-edit-container'}>
          <Form
            form={form}
            name="form_idea_edit"
            preserve={false}
            initialValues={{ remember: false, }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            <Col span={24}>

            </Col>
            <Col span={24}>
              <TextAreaIdea useClass={'Input-text-area-idea-edit'} initialValue={item.text} />
            </Col>

          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )

}


export default ModalFormEditIdea;
