import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page32 = () => {

  return (
    <MethodLayout
      title={"3.2 Reversed brainstorming"}
      prevNum={3.1}
      nextNum={3.3}
    >

      <b className='methods-layout-content-description'>
        Omvänd brainstorming drar fördel av den naturliga mänskliga tendensen att lättare se problem än lösningar. Dessutom kommer många individer när de placeras i en traditionell brainstormingmiljö att ha svårt att bli kreativa på kommando. <br />
        Omvänd brainstorming är en metod som går ut på att försöka göra problemet värre, och sen vända på det.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        individual
        groupSizeMin={7}
        groupSizeMax={10}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Skriv en problemformulering stort på whiteboarden / projektorn, ex. <i>hur kan vi få människor att välj kollektivtrafik i större utsträckning än de gör idag?</i></li>
        <li>Dela ut en ordentlig bunt post-it lappar till varje elev, och gå igenom innovationens grundprinciper (se presentationsmall).</li>
        <li>De ska nu på 10 minuter komma på så många olika sätt att göra problemet värre som de kan. En idé per post-it.</li>
        <li>Efter individuella stormningen ska de sättas i grupper om 3 - 5 pers. Här ska de nu sätta alla post-its på bordet / väggen. Använd med fördel toolie.se/groupie.</li>
        <li>Nu får gruppen 15 minuter på sig att gå igenom varje post-it och försöka vända på idéen. Ex. “dubbla biljettpriserna” blir “halvera biljettpriserna”.</li>
        <li>Avsluta med en kort presentation från varje grupp där de redogör för sina lösningar.</li>
      </ol>

    </MethodLayout>
  )
}
