import React from "react";
import '../SharedComponents.css'
import { BASE_PROJECT_COLORS } from "../../../util/colorConstants";

export const TextFieldCharCounter = ({ charCount, max }) => {


  function charMaxCount() {
    return charCount >= max
  }

  return (
    <React.Fragment>
      <div className={'Text-field-char-counter-container'}>
        <div>{`${charCount}`}</div>
        {'/'}
        <div style={{
          color: charMaxCount() ? BASE_PROJECT_COLORS.DARK : '#2d2d2d',
          fontWeight: 'bold'
        }}>{`${max}`}</div>

      </div>

    </React.Fragment>
  )
}
