import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page21 = () => {

  return (
    <MethodLayout
      title={"2.1 The 5 whys"}
      prevNum={1.3}
      nextNum={2.2}
    >
      <b className='methods-layout-content-description'>De fem varför (5 whys) är en beprövad och välanvänd metod för att utifrån en given problemformulering borra ner till roten av problemet, en så kallad rotfelsanalys.
        Likt en lök skalar varje svar på varför bort ett lager tills vi kommer till kärnan av problemet. <br />
        Att identifiera roten till problemet hjälper till att göra något åt orsaken och därmed åtgärda felet, istället för att enbart ta hand om symptomen.
      </b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        groupSizeMin={4}
        groupSizeMax={5}
        materials={["A4-papper", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Skriv en problemformulering stort på whiteboarden / projektorn, ex. <i>hur får vi människor att välja mer vegetarisk kost i sin vardag?</i></li>
        <li>Använd <i>toolie.se/groupie</i> för att dela in klassen i grupper om 4 - 5 pers med en framslumpad gruppledare.</li>
        <li>Be grupperna i diskutera <b>varför har vi det här problemet?</b>. Försök att på papper formulera svaret tydligt och konkret.</li>
        <li>Be grupperna återigen med utgångspunkt i deras formulerade svar ställa sig frågan <b>varför har vi det här problemet?</b></li>
        <li>Repetera denna varför-loop 3 ggr till (totalt 5 ggr), eller tills det inte finns några nya svar på frågan. Då har ni nått rot-orsaken.</li>
      </ol>


    </MethodLayout>
  )
}
