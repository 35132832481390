import React from "react";
import { Button, notification, Result } from "antd";
import { CheckSquareFilled, StopFilled } from "@ant-design/icons";
import { BASE_PROJECT_COLORS } from "../../util/colorConstants";


export function DisplayNotification(message, description, durationSeconds) {

  // console.log('NOTIFICATION_PROPS::', message, description)
  return (
    notification.open({
      message: message,
      description: description,
      duration: durationSeconds,
      placement: "bottomLeft",
    }))

}


export const ResultSuccess = ({
  title,
  subTitle,
  btnPrimaryText,
  btnSecondaryText,
  onButtonPrimaryClick,
  onButtonSecondaryClick
}) => {
  return (
    <Result
      icon={<CheckSquareFilled style={{ color: BASE_PROJECT_COLORS.LIGHTER }} />}
      title={title}
      subTitle={subTitle}
      extra={[
        <Button key="result-success-new-idea" onClick={onButtonPrimaryClick}>
          {btnPrimaryText}
        </Button>,
        <Button key="result-success-idea-list" onClick={onButtonSecondaryClick}>
          {btnSecondaryText}
        </Button>,
      ]}
    />
  )
}

export const ResultError = ({ title, subTitle, btnPrimaryText, onButtonPrimaryClick }) => {
  return (
    <Result
      status="error"
      title={title}
      subTitle={subTitle}
      extra={[
        <Button onClick={onButtonPrimaryClick}>
          {btnPrimaryText}
        </Button>
      ]}
    />
  )
}

export const ResultUserEmailPermissionDenied = ({ title, subTitle, extras }) => {
  return (
    <Result
      icon={<StopFilled style={{ color: 'white' }} />}
      title={<h2 style={{ color: 'white', fontSize: 'calc(14px + 0.5vmin)' }}>{title}</h2>}
      subTitle={<h2 style={{ color: 'white', fontSize: 'calc(12px + 0.5vmin)' }}>{subTitle}</h2>}
      extra={extras}
    />
  )
}
