import React from 'react';
import { MethodLayout, MethodInformation } from '../../components';

export const Page12 = () => {

  return (
    <MethodLayout
      title={"1.2 Multiple Perspective Problem Framing"}
      prevNum={1.1}
      nextNum={1.3}
    >

      <b className='methods-layout-content-description'>Vi människor blir väldigt snabbt “hemmablinda” och får tunnelseende när vi ska förstå och lösa problem.
        En konkret metod för att kringgå denna begränsning är att ta på sig olika “glasögon” vid granskning av samma problemområde.</b>

      <MethodInformation
        minutesFrom={30}
        minutesTo={45}
        groupSizeMin={3}
        groupSizeMax={4}
        materials={["Post-its", "Pennor", "Whiteboard"]}
      />

      <ol>
        <li>Klargör ett problemområde, litet som stort. Ex. lokala utmaningar med att återvinna plastprylar. Skriv det stort på whiteboarden / projektorn.</li>
        <li>Använd toolie.se/groupie för att dela in klassen i grupper om 3 - 4 pers. Ge varje grupp en stor hög med post-its.</li>
        <li>
          Läraren skriver upp 3-4 olika perspektiv relaterade till problemet på tavlan. Det kan vara:
          <div className='background-white-with-opacity query-flex-row' style={{ display: 'flex', maxWidth: '600px', marginTop: '1rem', marginBottom: '1rem', columnGap: '2rem' }}>
            <ul className='text-decoration-none'>
              <li>Optimist</li>
              <li>Pessimist</li>
              <li>Realist</li>
              <li>Visionär</li>
            </ul>
            <ul className='text-decoration-none'>
              <li>En ungdom</li>
              <li>En äldre</li>
              <li>En högskolestudent</li>
              <li>En småbarnsförälder</li>
            </ul>
            <ul className='text-decoration-none'>
              <li>Individ</li>
              <li>Grupp</li>
              <li>Organisation</li>
              <li>Samhälle</li>
            </ul>

          </div>

        </li>
        <li>Varje medlem i grupperna ansvarar för ett perspektiv. På 3 minuter ska de nu skriva ner hur deras perspektiv “ser” på problemområdet. En tanke per post-it.</li>
        <li>Nu får gruppen 15 minuter på sig att gå igenom varje post-it för att skapa sig en bättre och mer komplett bild av problemet.</li>
      </ol>
    </MethodLayout>
  )
}
