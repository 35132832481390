import React, { useEffect } from 'react'
import { STATIC_TEXT } from "../../util/staticTextConstants";
import { FirebaseAuth } from "../../firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import './AuthSignIn.css'

export const AuthSignIn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    FirebaseAuth.uiStart(dispatch, navigate)
  })

  return (
    <div className={'Sign-in-container'}>
      <img className={'Sign-in-logo'} src={'/LogoEx.svg'} alt={'logo'} />
      <b className={'Sign-in-title'}>{STATIC_TEXT.CREATE_ACCOUNT_NOT_SIGNED_IN_TITLE}</b>
      <p className={'Sign-in-text'}> {'Använd en giltig @ntig-address för att skapa ett konto eller logga in'}</p>
      <div id="firebaseui-auth-container"></div>
      {/*<div id="loader">Loading...</div>*/}
      {/*<AuthSignInButton/>*/}
    </div>
  )
}
