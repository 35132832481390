import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";
import React from "react";
import {BASE_PROJECT_COLORS} from "../../util/colorConstants";

const dateRanges = createDateRanges(10)

function createDateRanges(dateCount) {
  const result = [];
  const dateNow = new Date()
  for (let c = 1; c < dateCount + 1; c++) {
    const dated = new Date(dateNow.getTime() + (86400000 * c * 3));
    result.push(dated);
  }
  return result;
}

const mTIME_LINE_STATUS = [
  {
    key: "planned",
    color: BASE_PROJECT_COLORS.PURPLE_LIGHTER
  },
  {
    key: "progressing",
    color: BASE_PROJECT_COLORS.PURPLE_BADGER
  },
  {
    key: "done",
    color: BASE_PROJECT_COLORS.DARK_GRAY
  }
]

const mTIME_LINE_STATUS_CHILD = [
  {
    key: "child.planned",
    color: BASE_PROJECT_COLORS.PURPLE_LIGHTER
  },
  {
    key: "child.progressing",
    color: BASE_PROJECT_COLORS.PURPLE_BADGER
  },
  {
    key: "child.done",
    color: BASE_PROJECT_COLORS.DARK_GRAY
  }
]

const LIST_CARDS = Array.from({length: 15}, () => tempGenCardObject());
const LIST_CANDIDATES = [mCreateCandidates('candidate-1'), mCreateCandidates('candidate-2')];
const LIST_TIMELINE = Array.from({length: 10}, (v, i) => mTimeLineItem(i));


function tempGenCardObject() {
  return {
    id: createUUID(),
    title: `En titel fyller en stor funktion för att fånga ögat`,
    card_number: randomizeCount(123),
    text: 'Lorem Ipsum fyller en stor funktion i ddddddddddddd ddddddddddd ddddddddddddddd  dadsad  sad många sammanhang inte minst vid digital produktion och vid tillverkning av trycksaker. Ibland kan det dock bli riktigt tokigt om man glömmer att ta bort Lorem Ipsum och ersätta den med sin egen text. Här är några exempel på hur tokigt det kan bli:',
    email: 'ide.ideasson@ntig.se',
    hearted: !Math.round(Math.random()),
    hearted_count: randomizeCount(150),
    comments: Array.from({length: Math.floor(Math.random() * 10)}, () => mComments()),
    creation_date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).getTime(),
    created_by: createUUID(),
  }
}

function createIdea(title, text, email, userId) {
  return {
    id: createUUID(),
    title: title,
    card_number: randomizeCount(123),
    text: text,
    email: email,
    hearted: !Math.round(Math.random()),
    hearted_count: randomizeCount(150),
    comments: Array.from({length: Math.floor(Math.random() * 10)}, () => mComments()),
    creation_date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).getTime(),
    created_by: userId,
  }
}

function mTimeLineItem(i) {

  const randomizeStatus = randomizeCount(mTIME_LINE_STATUS.length)
  const CHILDREN_RANDOMIZE = Array.from({length: 5}, (v, c) => mTimeLineItemChild(c, dateRanges[i]));

  // alert(dateRanges)

  return   {
    date: dateRanges[i],
    title: `Parent ${i + 1}`,
    status: mTIME_LINE_STATUS[randomizeStatus],
    children: CHILDREN_RANDOMIZE
  }
}

function mTimeLineItemChild(c, date) {

  const randomizeStatus = randomizeCount(mTIME_LINE_STATUS_CHILD.length)

    return {
      title: `Child ${c + 1}`,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      date: date,
      status: mTIME_LINE_STATUS_CHILD[randomizeStatus],
    }
}



function mComments() {
  return {
    id: createUUID(),
    comment: 'Bra idé!',
    user: 'kom.kommentatorsson@ntig.se',
    creation_date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).getTime()
  }
}

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function randomizeCount(max) {
  return Math.floor(Math.random() * max);
}

function mCreateCandidates(id) {
  return {
    docId: id,
    appName: "Breakie",
    problem: "Det är torsdag eftermiddag. Eleverna åt lunch för 4 timmar sedan. De har haft lektion i samma sal i över två timmar och syret börjar ta slut. Eleverna är trötta, uttråkade och orkar inte lyssna på dig som är lärare längre. Ni känner säkert alla igen er i detta scenario - mer eller mindre.",
    solution: "Breakie, Appen som föreslår olika brainbreaks under lektionen. Läraren kan botanisera i ett galleri eller slumpa fram brainbreaks.",
    winnings: "Lärandet mår bra av fysiskt aktivitet. Med kortare breaks får hjärnan en paus och kan sedan fokusera bättre på lärandet.",
  }
}



export const Lists = {LIST_CARDS, LIST_TIMELINE, LIST_CANDIDATES}
export const MockFunctions = {randomizeCount, createIdea}
