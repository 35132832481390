import {configureStore} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import {mockFirestoreReducer} from '../features/firebase/mockFirestoreReducer';
import {firebaseAuthReducer} from '../features/firebase/firebaseAuthReducer';
import {firestoreIdeaSlice} from "../features/firebase/firestoreIdeaSlice";
import {firestoreCandidatesSlice} from "../features/firebase/firestoreCandidatesSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    mockFirestore: mockFirestoreReducer,
    firebaseAuth: firebaseAuthReducer,
    firestoreIdea: firestoreIdeaSlice.reducer,
    firestoreCandidates: firestoreCandidatesSlice.reducer,
  },
});
