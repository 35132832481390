import React from 'react'
import { Button, Col, Divider, Modal, Row } from "antd";
import { BASE_PROJECT_COLORS as COLORS } from "../../util/colorConstants";

//TODO: Played around with making modal with comment-section -- not structured
export const ModalComments = ({ modalVisible, displayModalComments, item }) => {

  return (
    <React.Fragment>

      <Modal
        title={item ? `${item.title} / ${item.id}` : 'Titel saknas'}
        centered
        visible={modalVisible}
        onOk={displayModalComments}
        // width={1000}
        onCancel={displayModalComments}
        footer={[
          <Button key="submit" type="link" style={{ color: COLORS.DARK }} onClick={displayModalComments}>
            OK
          </Button>,
        ]}
      >
        <Row style={{ marginBottom: 30 }}>
          <b>[VALT KORT]</b>
        </Row>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1%', width: '100%', minHeight: '5%' }}>
          {
            item.comments ? item.comments.map((item) => (
              <Col span={24} key={item.id}>
                <Row>
                  <Col span={18}>
                    <b>{item.user}</b>
                  </Col>
                  <Col span={6}>
                    <data>{`${item.creation_date}`}</data>
                  </Col>
                </Row>
                <Row>
                  <i>{item.comment}</i>
                </Row>

                <Divider />
              </Col>

            )
            )
              : null
          }
        </div>

      </Modal>

    </React.Fragment>
  )
}
