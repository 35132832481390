import React from "react";
import LogoEx from "../../assets/images/logo/LogoEx.svg";
import { NavLink } from "react-router-dom";
import { routes } from "../../router/Routes";
import { authedUser } from '../../features/firebase/firebaseAuthReducer';
import { useSelector } from 'react-redux';


export const Header = () => {

  // const location = useLocation();
  // const {pathname} = location;
  const user = useSelector(authedUser)

  return (
    <React.Fragment>
      <header className="App-header">
        <div className={"Header-logo-container"}>
          <img src={LogoEx} alt={'logo'} />
        </div>

        <div className={"Header-navigation-container"}>

          {user && routes.filter(x => x.requiresAuth).map(({ path, navBarDisplay, name, exact }) => (
            navBarDisplay.visible ?
              !navBarDisplay.iconOnly ?
                <NavLink className={'App-link'}
                  activeClassName='nav-active'
                  exact={exact}
                  to={path}>{name}</NavLink>
                :
                <NavLink className={'App-link'}
                  activeClassName='nav-active'
                  exact={exact}
                  to={path}>{navBarDisplay.icon}</NavLink>

              : null
          ))}

          {routes.filter(x => !x.requiresAuth).map(({ path, navBarDisplay, name, exact }, index) => (
            navBarDisplay.visible ?
              !navBarDisplay.iconOnly ?
                <NavLink className={'App-link'}
                  activeClassName='nav-active'
                  exact={exact}
                  key={index}
                  to={path}>{name}</NavLink>
                  :
                  <NavLink className={'App-link'}
                  activeClassName='nav-active'
                  exact={exact}
                  key={index}
                  to={path}>{navBarDisplay.icon}</NavLink>

              : null
          ))}


        </div>

      </header>
    </React.Fragment>
  )

}
